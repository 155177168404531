<div class="row pay-method-container px-3">
    <div class="col-12 pay-method-panel">
      <app-payment-method
        [paymentType]="paymentMethod.TYPE_CTX"
        [paymentProvider]="paymentProviderIdentifier"
        [paymentMethod]="conotoxiaPaymentMethod"
        [selected]="selectedPayMethodTypeCustom === paymentMethod.TYPE_CTX">
      </app-payment-method>
  </div>
</div>
