<div class="summary">
  <div class="py-5">
    <app-logo></app-logo>
  </div>
  <div class="py-5 text-center">
    <ng-container [ngSwitch]="status">
      <ng-container *ngSwitchCase="paymentStatusTypes.success">
        <h2>{{ 'app.page.payment.complete.first-line' | translate }}</h2>
        <p [innerHTML]="'app.page.payment.complete.seccond-line' | translate"></p>
        <h2>{{orderNumber}}</h2>
      </ng-container>
      <ng-container *ngSwitchCase="paymentStatusTypes.cancel">
        <h2>{{ 'app.page.payment.cancel.first-line' | translate }}</h2>
        <p>{{ 'app.page.payment.cancel.seccond-line' | translate }}</p>
      </ng-container>
      <ng-container *ngSwitchDefault>
        <h2>{{ 'app.page.payment.pending.first-line' | translate }}</h2>
        <p>{{ 'app.page.payment.pending.seccond-line' | translate }}</p>
        <button *ngIf="currentTry === maxTriesAmount" class="mx-0" mat-button color="primary"
                (click)="currentTry = 1; checkOrderStatus()">
          {{ 'button.check-again' | translate }}
        </button>
      </ng-container>
    </ng-container>
  </div>
</div>

<div class="footer footer-extra row">
  <div class="col-12">
    <button class="action-button mx-0" mat-raised-button color="primary" [routerLink]="['/']">
      {{ 'button.create-new-order' | translate }}
    </button>
  </div>
</div>