import { Expose } from 'class-transformer';

export class PaymentConfigApiModel {
  @Expose()
  salesPointId: string;

  @Expose()
  postBackUrl: string;

  @Expose()
  environment: string;

  @Expose()
  merchantId: string;

  @Expose()
  googleMerchantId: string;

  @Expose()
  merchantName: string;
}
