<div class="app-container mat-app-background" *ngIf="!orientationLandscape else landscapeTemplate"
     [@routeAnimations]="prepareRoute(outlet)">
  <router-outlet #outlet="outlet"></router-outlet>
  <div class="footer">{{'app.footer' | translate : {currentYear: currentYear} }}
    <span>ver.:{{currentApplicationVersion}}</span>
  </div>

  <app-loading></app-loading>
</div>

<ng-template #landscapeTemplate>
  <div class="landscape" [innerHtml]="'app.landscape-info' | translate"></div>
</ng-template>