import { AbstractViewModel } from '../../abstract.view.model';
import { ScreenRowApiModel } from '../../api-model/screen/screen-row.api.model';
import { IScreenRowModel } from '../../interfaces';

export class ScreenRowViewModel extends AbstractViewModel<ScreenRowApiModel> implements IScreenRowModel {
  id: string;
  legend: string;
  coordinate: number;

  constructor(protected override apiModel: ScreenRowApiModel = new ScreenRowApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.legend = this.apiModel.legend;
    this.coordinate = this.apiModel.coordinate;
  }

  toApiModel(): ScreenRowApiModel {
    return new ScreenRowApiModel();
  }

  override toString(): string {
    return this.legend;
  }
}
