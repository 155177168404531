import { Expose } from 'class-transformer';

export class TagApiModel {
  @Expose()
  orderNo: number;

  @Expose()
  symbol: string;

  @Expose()
  description: string;
}
