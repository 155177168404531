import { Expose, Type } from 'class-transformer';
import { CardItemMembershipPriceApiModel } from './card-item-membership-price.api.model';
import { CardItemShippingAddressApiModel } from './card-item-shipping-address.api.model';

export class CardItemApiModel {
  @Expose()
  id: string;

  @Expose()
  cardId: string;

  @Expose()
  cardTypeId: string;

  @Expose()
  cardBatchId: string;

  @Expose()
  cardBatchName: string;

  @Expose()
  cardPictureUrl: string;

  @Expose()
  name: string;

  @Expose()
  batchCardUnlimited: boolean;

  @Expose()
  value: number;

  @Expose()
  promotionId: string;

  @Expose()
  promotionName: string;

  @Expose()
  //@Type(() => CardItemShippingAddressApiModel)
  shippingAddress: CardItemShippingAddressApiModel;

  @Expose()
  //@Type(() => CardItemMembershipPriceApiModel)
  membershipPrices: CardItemMembershipPriceApiModel[] = [];
}
