export class PaymentPreInitModel {
  public continueUrl: string | null;
  public paymentData: { [key: string]: unknown };
  public intPayMethodType: string | null = null;
  public intPayMethodValue: string | null = null;
  public abort = false;

  public constructor() {
    this.continueUrl = null;
    this.paymentData = {};
    this.intPayMethodType = null;
    this.intPayMethodValue = null;
  }
}
