import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { VoucherApiModel } from '../models/api-model/voucher/voucher.api.model';

@Injectable({
  providedIn: 'root',
})
export class VoucherHttpService {
  constructor(private http: HttpClient) {}

  /**
   * Add voucher to order
   * @param cinemaId
   * @param orderId
   * @param request
   */
  public putVoucherToOrder(cinemaId: string, orderId: string, request: { voucherNumber: string }) {
    return this.http.put(`/cinema/${cinemaId}/order/${orderId}/voucher`, request);
  }

  /**
   * Remove all vouchers from order
   * @param cinemaId
   * @param orderId
   * @param voucherNumber
   */
  public removeVoucherFromOrder(cinemaId: string, orderId: string, voucherNumber: string) {
    return this.http.request('DELETE', `/cinema/${cinemaId}/order/${orderId}/voucher`, { body: { voucherNumber } });
  }

  /**
   * Add voucher to specified item
   * @param cinemaId
   * @param orderId
   * @param itemId
   * @param request
   */
  public putVoucherToOrderItem(cinemaId: string, orderId: string, itemId: string, request: { voucherNumber: string }) {
    return this.http.put(`/cinema/${cinemaId}/order/${orderId}/item/${itemId}/voucher`, request);
  }

  /**
   * Remove voucher from specified item
   * @param cinemaId
   * @param orderId
   * @param itemId
   * @param voucherNumber
   */
  public removeVoucherFromOrderItem(cinemaId: string, orderId: string, itemId: string, voucherNumber: string) {
    return this.http.request('DELETE', `/cinema/${cinemaId}/order/${orderId}/item/${itemId}/voucher`, { body: { voucherNumber } });
  }

  public assignVoucherToOrderItem(cinemaId: string, orderId: string, itemId: string, voucherNumber: string) {
    return this.http.put(`/cinema/${cinemaId}/order/${orderId}/item/${itemId}/voucher`, { voucherNumber });
  }

  /**
   * Returns information about Voucher
   * @param cinemaId
   * @param voucherNumber
   */
  public getInfo(cinemaId: string, voucherNumber: string): Observable<VoucherApiModel> {
    return this.http.get<VoucherApiModel>(`/cinema/${cinemaId}/voucher/${voucherNumber}`);
  }
}
