import { Expose } from 'class-transformer';

export class PaymentMethodApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  identifier: string;

  @Expose()
  cardId: string;

  @Expose() 3;
  value: number;
}
