import { Component, OnInit, Input, Output, EventEmitter, HostListener, forwardRef } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { noop, Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CheckListItemModel } from '../../../models/checklistitem.model';

const debouncer = new Subject<CheckListItemModel[]>();

@Component({
  selector: 'app-checklist',
  templateUrl: './checklist.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ChecklistComponent),
      multi: true,
    },
  ],
})
export class ChecklistComponent implements OnInit, ControlValueAccessor {
  public checkAll: CheckListItemModel = new CheckListItemModel('checkAllCheckbox', '');

  wasInside = false;
  touched = false;
  active = false;
  elementsTrans = 'elements';

  @Output() public changeEvent = new EventEmitter<CheckListItemModel[]>();
  @Input() public value: CheckListItemModel[];
  @Input() public showSelectedElements = false;
  @Input() public showCheckAll = true;
  @Input() public placeholder = '';
  @Input() disabled: boolean;

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.active = false;
    }
    this.wasInside = false;
  }

  constructor(protected translate: TranslateService) {
    debouncer.pipe(distinctUntilChanged(), debounceTime(400)).subscribe((value) => this.changeEvent.emit(value));
  }

  ngOnInit() {
    this.active = !this.showSelectedElements;
  }

  setAll() {
    if (this.value == null) {
      return;
    }
    this.value.forEach((t) => (t.checked = this.checkAll.checked));
    this.changeEventEmit();
    this.updateCheckAll();
  }

  updateCheckAll() {
    this.checkAll.checked = this.value != null && this.value.every((t) => t.checked);
    this.changeEventEmit();
    this.onTouched();
    this.onChange(this.value);
  }

  get selected() {
    if (this.value == null) {
      return '';
    }
    const checked = this.value.filter((o) => o.checked);
    if (checked.length === 0) {
      return '';
    } else if (checked.length > 3) {
      return `${checked.length} ${this.elementsTrans}`;
    }

    return checked
      .slice(0, checked.length)
      .map((o) => (o.translate ? this.translate.instant(o.name) : o.name))
      .join(',');
  }

  private changeEventEmit() {
    debouncer.next(this.value.filter((o) => o.checked));
  }

  onChange = (value: any) => {
    noop();
  };

  onTouched = () => {
    noop();
  };

  writeValue(value: any) {
    if (value.length > 0) {
      this.value = value;
      this.onChange(this.value);
    } else {
      this.value = [];
    }
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }
}
