import { AbstractViewModel } from '../../../abstract.view.model';
import { FbItemMembershipPriceApiModel } from '../../../api-model/order/fb-item/fb-item-membership-price.api.model';

export class FbItemMembershipPriceViewModel extends AbstractViewModel<FbItemMembershipPriceApiModel> {
  public isAppliedOnOrder: boolean;
  public membershipLevel: number;
  public membershipCardTypeId: string;
  public membershipPrice: number;

  constructor(protected apiModel: FbItemMembershipPriceApiModel = new FbItemMembershipPriceApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.isAppliedOnOrder = this.apiModel.isAppliedOnOrder;
    this.membershipLevel = this.apiModel.membershipLevel;
    this.membershipCardTypeId = this.apiModel.membershipCardTypeId;
    this.membershipPrice = this.apiModel.membershipPrice;
  }

  toApiModel(): FbItemMembershipPriceApiModel {
    return undefined;
  }
}
