import { CUSTOM_ELEMENTS_SCHEMA, Injector, ModuleWithProviders, NgModule, Optional, Provider, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ENVIRONMENT_TOKEN } from './injection.tokens';
import { HttpClientModule } from '@angular/common/http';
import { MaterialModule } from './modules/material/material.module';
import { LoadingComponent } from './components/loading/loading.component';
import { LogoComponent } from './components/logo/logo.component';
import { ScannerNoAccessComponent } from './components/notification-components/scanner-no-access/scanner-no-access.component';
import { MessageComponent } from './components/message/message.component';
import { LoadingService } from './components/loading/loading.service';
import { MessageService } from './components/message/service/message.service';
import { IosInstallComponent } from './components/ios-install/ios-install.component';
import { ImageLazyDirective } from './directives/image-lazy.directive';
import { DigitDirective } from './directives/digit-only.directive';
import { IframeTrackerDirective } from './directives/iframe-tracker.directive';
import { DateTimePipe } from './pipes/date-time.pipe';
import { MoneyPipe } from './pipes/money.pipe';
import { QuantityPipe } from './pipes/quantity.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { TranslateSelectorPipe } from './pipes/translate-selector.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PayuPaymentProviderComponent } from './components/payment/proxy/provider/payu/payu-payment-provider.component';
import { BasysPaymentProviderComponent } from './components/payment/proxy/provider/basys/basys-payment-provider.component';
import { ConotoxiaPaymentProviderComponent } from './components/payment/proxy/provider/conotoxia/conotoxia-payment-provider.component';
import { HyperpayPaymentProviderComponent } from './components/payment/proxy/provider/hyperpay/hyperpay-payment-provider.component';
import { IntercardPaymentProviderComponent } from './components/payment/proxy/provider/intercard/intercard-payment-provider.component';
import { PayuBlikPaymentProviderComponent } from './components/payment/proxy/provider/payu-blik/payu-blik-payment-provider.component';
import { SandboxPaymentProviderComponent } from './components/payment/proxy/provider/sandbox/sandbox-payment-provider.component';
import { WorldPayPaymentProviderComponent } from './components/payment/proxy/provider/worldpay/worldpay-payment-provider.component';
import { DatatransPaymentProviderComponent } from './components/payment/proxy/provider/datatrans/datatrans-payment-provider.component';

import { ArticleBuilderComponent } from './components/article-builder/article-builder.component';
import { ArticleCardComponent } from './components/article-card/article-card.component';
import { PaymentComponent } from './components/payment/payment.component';
import { PaymentMethodComponent } from './components/payment/proxy/component/payment-method/payment-method.component';
import { PaymentProxyComponent } from './components/payment/proxy/payment-proxy.component';
import { SearchFormComponent } from './components/search-form/search-form.component';
import { TipComponent } from './components/tip/tip.component';
import { MessagePageComponent } from './pages/message/message.component';
import { OrderPageComponent } from './pages/order/order.component';
import { PageNotFoundComponent } from './pages/page-not-found/page-not-found.component';
import { PaymentPageComponent } from './pages/payment/payment.component';
import { ProductDetailsPageComponent } from './pages/product-details/product-details.component';
import { ProductsPageComponent } from './pages/products/products.component';
import { RoutingHubComponent } from './pages/routing-hub/routing-hub.component';
import { ScannerComponent } from './pages/scanner/scanner.component';
import { WelcomePageComponent } from './pages/welcome/welcome.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { BrowserModule } from '@angular/platform-browser';
import { ResponseValidatorService } from './services/validators/response-validator.service';
import { OrderStateService } from './services/order.state.service';
import { SwUpdateService } from './services/update.service';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { NgxMaskModule } from 'ngx-mask';
import { RouterModule } from '@angular/router';
import { ProviderContainerComponent } from './components/payment/proxy/provider-container/provider-container.component';
import { SearchResultComponent } from './components/search-result/search-result.component';
import { MembershipLoginDialogComponent } from './components/dialogs/membership-login-dialog/membership-login-dialog.component';
import { SocialLoginModule } from './modules/auth/sociallogin.module';
import { PromoCodeComponent } from './components/promo-code/promo-code.component';
import { HideMissingDirective } from './directives/image-hide-missing.directive';
import { ChecklistComponent } from './components/ui/checklist/checklist.component';

@NgModule({
  declarations: [
    //components
    LoadingComponent,
    LogoComponent,
    ScannerNoAccessComponent,
    MessageComponent,
    IosInstallComponent,
    ScannerComponent,
    ProductDetailsPageComponent,
    ArticleBuilderComponent,
    OrderPageComponent,
    ArticleCardComponent,
    SearchResultComponent,
    SearchFormComponent,
    ProductsPageComponent,
    TipComponent,
    PaymentComponent,
    PaymentPageComponent,
    RoutingHubComponent,
    PageNotFoundComponent,
    MessagePageComponent,
    PaymentMethodComponent,
    PayuPaymentProviderComponent,
    PayuBlikPaymentProviderComponent,
    SandboxPaymentProviderComponent,
    WorldPayPaymentProviderComponent,
    DatatransPaymentProviderComponent,
    ConotoxiaPaymentProviderComponent,
    IntercardPaymentProviderComponent,
    BasysPaymentProviderComponent,
    HyperpayPaymentProviderComponent,
    PaymentProxyComponent,
    WelcomePageComponent,
    ProviderContainerComponent,
    MembershipLoginDialogComponent,
    PromoCodeComponent,
    ChecklistComponent,
    //directives
    HideMissingDirective,
    ImageLazyDirective,
    IframeTrackerDirective,
    DigitDirective,
    //pipes
    MoneyPipe,
    QuantityPipe,
    DateTimePipe,
    TranslateSelectorPipe,
    SafePipe,
  ],
  imports: [
    RouterModule,
    BrowserModule,
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    TranslateModule.forChild(),
    HttpClientModule,
    ZXingScannerModule,
    NgxQRCodeModule,
    NgxMaskModule.forRoot(),
    ModalModule.forRoot(),
    SocialLoginModule,
  ],
  exports: [
    //modules
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MaterialModule,
    ZXingScannerModule,
    NgxQRCodeModule,
    SocialLoginModule,
    //components
    LoadingComponent,
    LogoComponent,
    MessageComponent,
    IosInstallComponent,
    ScannerComponent,
    ProductDetailsPageComponent,
    ArticleBuilderComponent,
    OrderPageComponent,
    ArticleCardComponent,
    SearchResultComponent,
    SearchFormComponent,
    ProductsPageComponent,
    TipComponent,
    PaymentComponent,
    PaymentPageComponent,
    RoutingHubComponent,
    PageNotFoundComponent,
    MessagePageComponent,
    PaymentMethodComponent,
    PayuPaymentProviderComponent,
    PayuBlikPaymentProviderComponent,
    SandboxPaymentProviderComponent,
    WorldPayPaymentProviderComponent,
    DatatransPaymentProviderComponent,
    ConotoxiaPaymentProviderComponent,
    IntercardPaymentProviderComponent,
    BasysPaymentProviderComponent,
    HyperpayPaymentProviderComponent,
    PaymentProxyComponent,
    WelcomePageComponent,
    PromoCodeComponent,
    ChecklistComponent,
    //directives
    HideMissingDirective,
    ImageLazyDirective,
    IframeTrackerDirective,
    DigitDirective,
    //pipes
    MoneyPipe,
    QuantityPipe,
    DateTimePipe,
    TranslateSelectorPipe,
    SafePipe,
    //dialogs
    MembershipLoginDialogComponent,
  ],
  entryComponents: [
    PayuPaymentProviderComponent,
    HyperpayPaymentProviderComponent,
    PayuBlikPaymentProviderComponent,
    BasysPaymentProviderComponent,
    ConotoxiaPaymentProviderComponent,
    IntercardPaymentProviderComponent,
    SandboxPaymentProviderComponent,
    WorldPayPaymentProviderComponent,
    DatatransPaymentProviderComponent,
  ],
  providers: [LoadingService, MessageService, ResponseValidatorService, OrderStateService, SwUpdateService],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CoreLibraryModule {
  constructor(@Optional() @SkipSelf() parentModule: CoreLibraryModule, private injector: Injector) {
    if (parentModule) {
      throw new Error('CoreLibraryModule is already loaded. Import it in the AppModule only');
    }
  }

  public static forRoot(environment: any, providers: Provider[] = []): ModuleWithProviders<CoreLibraryModule> {
    return {
      ngModule: CoreLibraryModule,
      providers: [
        {
          provide: ENVIRONMENT_TOKEN,
          useValue: environment,
        },
        ...[providers],
      ],
    };
  }
}
