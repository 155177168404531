import { ComponentFactory, ComponentFactoryResolver, Injectable, Type } from '@angular/core';
import { BasysPaymentProviderComponent } from '../provider/basys/basys-payment-provider.component';
import { ConotoxiaPaymentProviderComponent } from '../provider/conotoxia/conotoxia-payment-provider.component';
import { HyperpayPaymentProviderComponent } from '../provider/hyperpay/hyperpay-payment-provider.component';
import { PaymentProviderComponentInterface } from '../provider/payment-provider.component.interface';
import { PayuBlikPaymentProviderComponent } from '../provider/payu-blik/payu-blik-payment-provider.component';
import { PayuPaymentProviderComponent } from '../provider/payu/payu-payment-provider.component';
import { SandboxPaymentProviderComponent } from '../provider/sandbox/sandbox-payment-provider.component';
import { WorldPayPaymentProviderComponent } from '../provider/worldpay/worldpay-payment-provider.component';
import { DatatransPaymentProviderComponent } from '../provider/datatrans/datatrans-payment-provider.component';
import { CorvusPayPaymentProviderComponent } from '../provider/corvuspay/corvuspay-payment-provider.component';

@Injectable({
  providedIn: 'root',
})
export class PaymentProviderComponentFactory {
  private componentMap: { [type: string]: unknown } = {
    payu: PayuPaymentProviderComponent,
    hyperpay: HyperpayPaymentProviderComponent,
    payu_blik: PayuBlikPaymentProviderComponent,
    basys: BasysPaymentProviderComponent,
    cinkciarz: ConotoxiaPaymentProviderComponent,
    intercard: HyperpayPaymentProviderComponent,
    sandbox: SandboxPaymentProviderComponent,
    worldpay: WorldPayPaymentProviderComponent,
    datatrans: DatatransPaymentProviderComponent,
    corvuspay: CorvusPayPaymentProviderComponent,
  };

  public constructor(private resolver: ComponentFactoryResolver) {}

  public getFactory(providerType: string): ComponentFactory<PaymentProviderComponentInterface> {
    if (this.componentMap[providerType]) {
      return this.resolver.resolveComponentFactory(this.componentMap[providerType] as Type<PaymentProviderComponentInterface>);
    }

    throw new Error(`Unknown provider type '${providerType}'`);
  }
}
