import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../abstract.view.model';
import { CardApiModel } from '../../api-model/card/card.api.model';
import { CardTypeViewModel } from './card-type.view.model';

export class CardViewModel extends AbstractViewModel<CardApiModel> {
  public id: string = null;
  public name: string = null;
  public pointBalance: number = null;
  public valueBalance: number = null;
  public statusId: number = null;
  public cardTypeId: string = null;
  public clientId: string = null;
  public status: boolean = null;
  public issueDate: DateTime = null;
  public salesChannelId: string = null;
  public cardBatchId: string = null;
  public flgUseInLocal: number = null;
  public cinemaId: string = null;
  public expireDate: DateTime = null;
  public message: string = null;
  public templateId: string = null;
  public isHidden: boolean = null;
  public type: number;

  public picture: string = null;
  public cardType: CardTypeViewModel = null;

  constructor(protected apiModel: CardApiModel = new CardApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.pointBalance = this.apiModel.pointBalance;
    this.valueBalance = this.apiModel.valueBalance;
    this.statusId = this.apiModel.statusId;
    this.cardTypeId = this.apiModel.cardTypeId;
    this.clientId = this.apiModel.clientId;
    this.status = this.apiModel.status;
    this.issueDate = this.apiModel.issueDate ? DateTime.fromISO(this.apiModel.issueDate) : null;
    this.salesChannelId = this.apiModel.salesChannelId;
    this.cardBatchId = this.apiModel.cardBatchId;
    this.flgUseInLocal = this.apiModel.flgUseInLocal;
    this.cinemaId = this.apiModel.cinemaId;
    this.expireDate = this.apiModel.expireDate ? DateTime.fromISO(this.apiModel.expireDate) : null;
    this.message = this.apiModel.message;
    this.templateId = this.apiModel.templateId;
    this.isHidden = this.apiModel.isHidden;
    this.type = this.apiModel.type;
  }

  toApiModel(): CardApiModel {
    return undefined;
  }
}
