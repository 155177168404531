export class OrderPaymentRequestModel {
  public cinemaId: string;

  public orderId: string;

  public paymentChannel: string | null = null;

  public paymentProviderIdentifier: string | null = null;

  public continueUrl: string | null = null;

  public paymentData: { [key: string]: any };

  public intPayMethodType: string | null = null;

  public intPayMethodValue: string | null = null;

  public savePayment: boolean;

  public paymentToken: string | null = null;
}
