import { Expose } from 'class-transformer';

export class CardItemShippingAddressApiModel {
  @Expose()
  firstName: string;

  @Expose()
  lastName: string;

  @Expose()
  city: string;

  @Expose()
  street: string;

  @Expose()
  streetNumber: string;

  @Expose()
  postalCode: string;

  @Expose()
  address1: string;

  @Expose()
  address2: string;
}
