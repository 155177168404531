import { Expose } from 'class-transformer';

export class CardTypeBatchApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  graphic: string;

  @Expose()
  unlimited: boolean;

  @Expose()
  canBeIssued: boolean;

  @Expose()
  paymentValue: number;
}
