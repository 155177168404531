import { Expose } from 'class-transformer';

export class ScreeningItemMembershipPriceApiModel {
  @Expose()
  isAppliedOnOrder: boolean;

  @Expose()
  membershipLevel: number;

  @Expose()
  membershipCardTypeId: string;

  @Expose()
  membershipTicketPrice: number;

  @Expose()
  membershipPrice: number;
}
