import { DateTime } from 'luxon';

export class ScreeningRequestModel {
  cinema: string;
  dateTimeFrom: string;
  dateTimeTo: string;
  regionId: string;

  deserialize(cinemaId: string, dateFrom: DateTime, dateTo?: DateTime) {
    this.cinema = cinemaId;
    this.dateTimeFrom = dateFrom.startOf('day').toISO({ includeOffset: false });
    this.dateTimeTo = dateTo ? dateTo.endOf('day').toISO({ includeOffset: false }) : dateFrom.endOf('day').toISO({ includeOffset: false });
    this.setDates(dateFrom, dateTo);
    return this;
  }

  forRegion(regionId: string, from: DateTime, to?: DateTime) {
    this.regionId = regionId;
    this.setDates(from, to);
    return this;
  }

  private setDates(from: DateTime, to?: DateTime) {
    if (!to) {
      to = from;
    }
    this.dateTimeFrom = from.startOf('day').toISO({ includeOffset: false });
    this.dateTimeTo = to.endOf('day').toISO({ includeOffset: false });
  }
}
