import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { PaymentProviderStateService } from '../../../service/payment-provider-state.service';
import { PaymentProviderEvent } from '../../event/payment-provider.event';
import { PaymentPreInitModel } from '../../model/payment-pre-init.model';
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';
import { IntercardInternalPaymentMethodType } from './model/intercard-internal-payment-method-type';
import { OrderStateModel } from '../../../../../models/order.state.model';
import { PaymentViewModel } from '../../../../../models/view-model/sales-document/payment.view.model';

@Component({
  selector: 'app-payment-provider-intercard-component',
  templateUrl: './intercard-payment-provider.component.html',
})
export class IntercardPaymentProviderComponent implements PaymentProviderComponentInterface, OnInit {
  events: EventEmitter<PaymentProviderEvent> = new EventEmitter<PaymentProviderEvent>();

  @Input() public orderState: OrderStateModel = null;
  public interCardForm: FormGroup;
  public formSubmitAttempt = false;

  constructor(public paymentProviderStateService: PaymentProviderStateService) {}

  onPostInitPayment(paymentModel: PaymentViewModel): void {
    window.location.href = paymentModel.plainPayload;
  }

  onPreInitPayment(event: PaymentPreInitModel): Observable<PaymentPreInitModel> {
    this.paymentProviderStateService.formSubmitAttempt = true;

    return new Observable<PaymentPreInitModel>((subscriber) => {
      if (this.interCardForm.valid) {
        event.intPayMethodType = IntercardInternalPaymentMethodType.card;
        event.intPayMethodValue = this.interCardForm.get('accountNumber').value;
      } else {
        event.abort = true;
      }
      subscriber.next(event);
      subscriber.complete();
    });
  }

  ngOnInit() {
    this.interCardForm = this.paymentProviderStateService.getIntercardForm();
    Object.keys(this.interCardForm.controls).forEach((key) => {
      this.interCardForm.controls[key].setValue(null);
    });
  }

  public formatAccountNumber(): void {
    const value = this.interCardForm.get('accountNumber').value.replace(new RegExp(/[^\d/]/, 'g'), '');
    this.interCardForm.get('accountNumber').setValue(value);
  }
}
