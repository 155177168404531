import { enableProdMode } from '@angular/core';

export abstract class ConfigService {
  public static setEnvironment(config, environment) {
    if (!config) return;

    environment.apiUrl = config.apiUrl || environment.apiUrl;
    environment.rulesUrl = config.rulesUrl || environment.rulesUrl;
    environment.baseImgOnError = config.baseImgOnError || environment.baseImgOnError;
    if (config.globalize) {
      environment.globalize.currency = config.globalize.currency || environment.globalize.currency;
      if (config.globalize.language) {
        environment.globalize.language.default = config.globalize.language.default ?? environment.globalize.language.default;
        environment.globalize.language.available = config.globalize.language.available ?? environment.globalize.language.available;
        environment.globalize.language.cultureMap = config.globalize.language.cultureMap ?? environment.globalize.language.cultureMap;
      }
    }
    environment.tips = config.tips || environment.tips;
    if (config.brand) {
      environment.brand.fullLogo = config.brand.fullLogo || environment.brand.fullLogo;
      environment.brand.subLogo = config.brand.subLogo || environment.brand.subLogo;
    }
    if (config.payment) {
      environment.payment.provider = config.payment.provider ?? environment.payment.provider;
      environment.payment.giftcard = config.payment.giftcard ?? environment.payment.giftcard;
      environment.payment.channel = config.payment.channel ?? environment.payment.channel;
      environment.payment.allowedPaymentMethod = config.payment.allowedPaymentMethod ?? environment.payment.allowedPaymentMethod;
      environment.payment.paymentRedirectUrl = config.payment.paymentRedirectUrl ?? environment.payment.paymentRedirectUrl;
    }
    if (config.features) {
      environment.features = Object.assign(environment.features, config.features);
    }

    if (config.theme) {
      environment.theme = config.theme ?? environment.theme;
    }

    if (config.order?.orderAgreementGroupId) {
      environment.order.orderAgreementGroupId = config.order.orderAgreementGroupId;
    }

    this.initialize(environment);
  }

  public static initialize(environment) {
    if (environment.production) {
      enableProdMode();
      if (!environment.activeLogger) {
        // disable any console.log debugging statements in production mode
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        window.console.log = function () {};
        // disable any console.warn debugging statements in production mode
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        window.console.warn = function () {};
        // window.console.error = function () { };
      }
    }
  }
}
