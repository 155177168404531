import { Component, Inject } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA, MatDialogRef, MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { FormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AuthStateService } from '../../../services/auth.service';
import { UserDataProvider } from '../../../data-providers/user.data-provider';
import { UserLoginRequestModel } from '../../../models/request/user-login.request.model';
import { UserLoginResponseModel } from '../../../models/response/user-login.response.model';
import { HttpErrorResponse } from '@angular/common/http';
import { LoadingService } from '../../loading/loading.service';
import { OrderStateModel } from '../../../models/order.state.model';
import { OrderHelper } from '../../../helpers/order.helper';
import { NotificationService } from '../../../services/notification.service';
import { ErrorHelper } from '../../../helpers/error-helper';
import { TranslateService } from '@ngx-translate/core';

export interface MembershipLoginDialogData {
  email: string;
  password: string;
  completed: boolean;
  orderState: OrderStateModel;
}

@Component({
  selector: 'app-membership-login-dialog',
  templateUrl: './membership-login-dialog.component.html',
})
export class MembershipLoginDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<MembershipLoginDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: MembershipLoginDialogData,
    protected authStateService: AuthStateService,
    protected orderHelper: OrderHelper,
    protected userDataProvider: UserDataProvider,
    protected loadingService: LoadingService,
    protected notificationService: NotificationService,
    private translation: TranslateService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  login() {
    this.loadingService.showLoader();

    const loginRequestModel: UserLoginRequestModel = new UserLoginRequestModel();
    loginRequestModel.username = this.data.email;
    loginRequestModel.password = this.data.password;
    loginRequestModel.rememberMe = false;

    this.userDataProvider.login(loginRequestModel).subscribe({
      next: (resultLogin) => this.handleToken(resultLogin),
      error: (error: HttpErrorResponse) => {
        this.notificationService.showError(this.translation.instant('error.' + ErrorHelper.getCode(error)));
        this.loadingService.hideLoader();
      },
    });
  }

  private handleToken(response: UserLoginResponseModel): void {
    this.authStateService.handleToken(response).subscribe((data) => {
      this.orderHelper.updateOrderMembershipCard().subscribe((s) => {
        this.notificationService.show(this.translation.instant('app.components.dialogs.membership-login.success'));
        this.data.completed = true;
        this.loadingService.hideLoader();
        this.dialogRef.close(this.data);
      });
    });
  }
}
