import { Expose } from 'class-transformer';

export class FbItemModifierItemApiModel {
  @Expose()
  modifierId: string;

  @Expose()
  name: string;

  @Expose()
  isSeparate: boolean;

  @Expose()
  modifierName: string;

  @Expose()
  modifierItemId: string;

  @Expose()
  modifierItemName: string;

  @Expose()
  modifierItemDescription: string;

  @Expose()
  quantity: number;

  @Expose()
  taxRate: number;

  @Expose()
  price: number;

  @Expose()
  primaryModifierItemId: string;

  @Expose()
  defaultPriceLevelPrice: number;
}
