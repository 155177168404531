import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  MessagePageComponent,
  PageNotFoundComponent,
  PaymentPageComponent,
  ProductsPageComponent,
  RoutingHubComponent,
  ScannerComponent,
  WelcomePageComponent,
} from '@dinein/core';
import { CinestarOrderPageComponent } from './pages/order/order.component';

const routes: Routes = [
  { path: 'payment/:status', component: PaymentPageComponent },
  { path: 'payment', component: PaymentPageComponent },
  { path: 'welcome', component: WelcomePageComponent },
  { path: 'products', component: ProductsPageComponent },
  { path: 'init/:cinemaId/:seatId', component: RoutingHubComponent },
  { path: 'init/:cinemaId', component: RoutingHubComponent },
  { path: 'scanner', component: ScannerComponent },
  {
    path: 'order',
    component: CinestarOrderPageComponent,
    data: { animation: 'isRight' },
  },
  { path: 'error/:id', component: MessagePageComponent },
  { path: 'index.html', component: PageNotFoundComponent },
  { path: 'p', redirectTo: '/payment', pathMatch: 'full' },
  { path: ':cinemaId/:seatId', redirectTo: '/init/:cinemaId/:seatId' },
  { path: ':cinemaId', redirectTo: '/init/:cinemaId' },
  { path: '', component: RoutingHubComponent },
  { path: '**', component: PageNotFoundComponent },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      enableTracing: false,
      useHash: false,
      anchorScrolling: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
