import { AbstractViewModel } from '../../../abstract.view.model';
import { CardTypeItemApiModel } from '../../../api-model/order/card-type-item/card-type-item.api.model';
import { CardTypeItemMembershipPriceViewModel } from './card-type-item-membership-price.view.model';

export class CardTypeItemViewModel extends AbstractViewModel<CardTypeItemApiModel> {
  id: string;
  itemId: string;
  typeId: string;
  templateId: string;
  chargeValue: number;
  email: string;
  message: string;
  promotionId: string;
  promotionName: string;
  name: string;
  quantity: number;
  taxRate: number;
  itemPrice: number;
  cardId: string;
  itemDefaultPriceLevelPrice: number;
  itemEarnedPoints: number;
  itemRedemptionPoints: number;
  membershipPrices: CardTypeItemMembershipPriceViewModel[] = [];

  profit: number;

  constructor(protected apiModel: CardTypeItemApiModel = new CardTypeItemApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.itemId = this.apiModel.itemId;
    this.typeId = this.apiModel.typeId;
    this.templateId = this.apiModel.templateId;
    this.chargeValue = this.apiModel.chargeValue;
    this.email = this.apiModel.email;
    this.message = this.apiModel.message;
    this.promotionId = this.apiModel.promotionId;
    this.promotionName = this.apiModel.promotionName;
    this.name = this.apiModel.name;
    this.quantity = this.apiModel.quantity;
    this.taxRate = this.apiModel.taxRate;
    this.itemPrice = this.apiModel.itemPrice;
    this.cardId = this.apiModel.cardId;
    this.itemDefaultPriceLevelPrice = this.apiModel.itemDefaultPriceLevelPrice;
    this.itemEarnedPoints = this.apiModel.itemEarnedPoints;
    this.itemRedemptionPoints = this.apiModel.itemRedemptionPoints;
    this.membershipPrices = this.apiModel.membershipPrices
      ? this.apiModel.membershipPrices.map((membershipPrice) => new CardTypeItemMembershipPriceViewModel(membershipPrice))
      : [];
  }

  toApiModel(): CardTypeItemApiModel {
    return undefined;
  }
}
