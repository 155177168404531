import { Expose } from 'class-transformer';

export class DefaultExtraFeeMembershipPriceApiModel {
  @Expose()
  isAppliedOnOrder: boolean;

  @Expose()
  membershipLevel: number;

  @Expose()
  membershipCardTypeId: string;

  @Expose()
  membershipDefaultExtraFeePrice: number;
}
