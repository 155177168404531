import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';
import { LoadingService } from '../../components/loading/loading.service';

@Component({
  selector: 'app-page-message',
  templateUrl: './message.component.html',
})
export class MessagePageComponent implements OnInit {
  public id: string = null;
  public message: any = null;

  constructor(private loadingServide: LoadingService, private route: ActivatedRoute, private translateService: TranslateService) {}

  ngOnInit() {
    this.loadingServide.hideLoader();

    this.route.params.subscribe((params) => {
      this.id = params['id'];
      this.prepareMessage(this.id);
    });
  }

  private prepareMessage(id: string) {
    this.translateService.get(`message.${id}`).subscribe(
      (res: object) => {
        this.message = res;
      },
      (err) => {
        this.translateService.get(`message.default`).subscribe((res: object) => (this.message = res));
      }
    );
  }

  goAction() {
    window.location.href = '/';
  }
}
