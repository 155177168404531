import { Expose } from 'class-transformer';

export class PaymentProviderPayMethodApiModel {
  @Expose()
  public id: string;

  @Expose()
  public type: string;

  @Expose()
  public subType: string | null;

  @Expose()
  public name: string | null;

  @Expose()
  public imageUrl: string | null;

  @Expose()
  public minAmount: number | null;

  @Expose()
  public maxAmount: number | null;

  @Expose()
  public status: string | null;

  @Expose()
  public isPreferred: boolean;

  @Expose()
  public cardExpirationYear: string | null;

  @Expose()
  public cardExpirationMonth: string | null;

  @Expose()
  public cardMask: string | null;

  @Expose()
  public cardBrand: string | null;

  @Expose()
  public canBeSaved: boolean;

  @Expose()
  public token: string | null;

  public canBeShown = true;
}
