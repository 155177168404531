import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DateTime } from 'luxon';
import { BaseDataProvider } from '../../data-providers/base.data-provider';
import { OrderStateService } from '../../services/order.state.service';
import { LoadingService } from '../../components/loading/loading.service';
import { storageKey } from '../../app.consts';

@Component({
  template: '',
})
export class RoutingHubComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private orderStateService: OrderStateService,
    private baseDataProvider: BaseDataProvider,
    private loadingServide: LoadingService,
    private router: Router
  ) {}

  ngOnInit(): void {
    const cinemaId = this.route.snapshot.params['cinemaId'];
    const seatId = this.route.snapshot.params['seatId'];

    if (cinemaId) {
      this.loadingServide.showLoader();
      const localDate = DateTime.local();
      this.orderStateService.initByParams(cinemaId, seatId);
      if (seatId) {
        this.baseDataProvider.findScreening(cinemaId, seatId, localDate).subscribe((screeningInfo) => {
          this.orderStateService.storeScreeningInfo(screeningInfo);
        });
      }

      sessionStorage.setItem(storageKey.welcomePage, 'true');

      setTimeout(() => {
        this.router.navigate(['products']);
      }, 400);

      return;
    }

    const queryParams = this.route.snapshot.queryParams;
    const hostedPaymentStatus = queryParams['HostedPaymentStatus'];
    if (hostedPaymentStatus) {
      if (window.parent) {
        window.parent.location.href = `/payment/${hostedPaymentStatus}`;
      } else {
        window.location.href = `/payment/${hostedPaymentStatus}`;
      }
      return;
    }

    this.router.navigate(['products']);
  }
}
