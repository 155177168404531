import { Expose } from 'class-transformer';

export class CardItemMembershipPriceApiModel {
  @Expose()
  isAppliedOnOrder: boolean;

  @Expose()
  membershipLevel: number;

  @Expose()
  membershipCardTypeId: string;

  @Expose()
  membershipValue: number;
}
