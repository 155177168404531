<h1 mat-dialog-title>{{data.email}}</h1>
<div mat-dialog-content>
    <p>{{'app.components.dialogs.membership-login.enter-password' | translate }}</p>
    <mat-form-field>
        <mat-label>{{'app.components.dialogs.membership-login.password' | translate }}</mat-label>
        <input type="password" matInput [(ngModel)]="data.password">
    </mat-form-field>
</div>
<div mat-dialog-actions>
    <button mat-button (click)="onNoClick()">{{'button.no-thanks' | translate }}</button>
    <button mat-button color="primary" (click)="login()" cdkFocusInitial>{{'button.login' | translate }}</button>
</div>