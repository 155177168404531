import { NgModule, Optional, SkipSelf, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';

import { SocialAuthService } from './socialauth.service';
import { GoogleButtonDirective } from './directives/google-button.directive';
import { AppleButtonDirective } from './directives/apple-button.directive';
import { SocialAuthServiceConfig } from './interfaces/socialauth.interface';
/**
 * The main module of angularx-social-login library.
 */
@NgModule({
  declarations: [GoogleButtonDirective, AppleButtonDirective],
  imports: [CommonModule],
  providers: [SocialAuthService],
  exports: [GoogleButtonDirective, AppleButtonDirective],
})
export class SocialLoginModule {
  public static initialize(config: SocialAuthServiceConfig): ModuleWithProviders<SocialLoginModule> {
    return {
      ngModule: SocialLoginModule,
      providers: [
        SocialAuthService,
        {
          provide: 'SocialAuthServiceConfig',
          useValue: config,
        },
      ],
    };
  }

  constructor(@Optional() @SkipSelf() parentModule: SocialLoginModule) {
    if (parentModule) {
      throw new Error('SocialLoginModule is already loaded. Import it in the AppModule only');
    }
  }
}
