import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { CreatePaymentRedirectUrlDto } from '../../../service/dto/create-payment-redirect-url.dto';
import { PaymentHelperService } from '../../../service/payment-helper.service';
import { PaymentProviderEvent } from '../../event/payment-provider.event';
import { PaymentMethodEnum } from '../../model/payment-method.enum';
import { PaymentPreInitModel } from '../../model/payment-pre-init.model';
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';
import { OrderDataProvider } from '../../../../../data-providers/order.data-provider';
import { OrderStateModel } from '../../../../../models/order.state.model';
import { PaymentViewModel } from '../../../../../models/view-model/sales-document/payment.view.model';
import { OrderStateService } from '../../../../../services/order.state.service';
import { ENVIRONMENT_TOKEN } from '../../../../../injection.tokens';

@Component({
  selector: 'app-payment-provider-sandbox-component',
  templateUrl: './sandbox-payment-provider.component.html',
})
export class SandboxPaymentProviderComponent implements PaymentProviderComponentInterface, OnInit {
  env: any;

  @Output() public events = new EventEmitter<PaymentProviderEvent>();

  public paymentMethod = PaymentMethodEnum;
  public readonly paymentProviderIdentifier: string = 'sandbox';
  private orderState: OrderStateModel = null;
  public paymentChannel: string = null;

  public constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment: any,
    private orderDataProvider: OrderDataProvider,
    private orderStateService: OrderStateService,
    private paymentHelperService: PaymentHelperService,
    private route: ActivatedRoute
  ) {
    this.env = environment;
  }

  public ngOnInit(): void {
    this.paymentChannel = this.env.payment.channel;
    this.orderState = this.orderStateService.getState();
  }

  public onPostInitPayment(paymentModel: PaymentViewModel): void {
    setTimeout(() => {
      if (paymentModel.plainPayload) {
        window.location.href = paymentModel.plainPayload;
        return;
      }

      const request: CreatePaymentRedirectUrlDto = new CreatePaymentRedirectUrlDto();
      request.paymentChannel = this.paymentChannel;
      request.state = this.orderState;
      request.route = this.route;

      window.location.href = this.paymentHelperService.createPaymentRedirectUrl(request);
    }, 2000);
  }

  public onPreInitPayment(event: PaymentPreInitModel): Observable<PaymentPreInitModel> {
    return new Observable<PaymentPreInitModel>((subscriber) => {
      event.intPayMethodType = 'user';
      event.intPayMethodValue = '';
      subscriber.next(event);
      subscriber.complete();
    });
  }
}
