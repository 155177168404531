import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import * as _ from 'lodash';
import { ArticleCard } from '../../models/article-card.model';
import { CardActionTypeEnum } from '../../models/card-action-type.enum';
import { CateringAggregationArticleModifierItemViewModel, CateringAggregationArticleViewModel } from '../../models/catering-aggregation.view.model';
import { CartService } from '../../services/cart.service';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { ProductDetailsPageComponent } from '../../pages/product-details/product-details.component';
import { OrderStateService } from '../../services/order.state.service';

@Component({
  selector: 'app-article-card',
  templateUrl: './article-card.component.html',
})
export class ArticleCardComponent {
  @Input() disabled = false;
  bsModalRef?: BsModalRef;
  hide = false;

  _item: CateringAggregationArticleViewModel;
  get item(): CateringAggregationArticleViewModel {
    return this._item;
  }
  @Input() set item(value: CateringAggregationArticleViewModel) {
    //console.log('article',value);
    this._item = value;
    this.setValues();
  }

  _totalQuantity: number | null = null;
  public get totalQuantity() {
    return this._totalQuantity;
  }

  _hasAnyMods = false;
  public get hasAnyMods() {
    return this._hasAnyMods;
  }

  @Input() mode = '';
  @Output() public articleCardChange: EventEmitter<ArticleCard> = new EventEmitter<ArticleCard>();
  public cardActionTypeEnum: typeof CardActionTypeEnum = CardActionTypeEnum;

  constructor(private cartService: CartService, private orderStateService: OrderStateService, private router: Router, private modalService: BsModalService) {}

  setValues() {
    if (this.item) {
      this.item.orderArticles = _.orderBy(this.item.orderArticles, ['price'], ['asc']);
      this._totalQuantity = _.sumBy(this.item.orderArticles, 'selectedQuantity');
      this._hasAnyMods = !!this.item.modifierArticleList?.length || (this.item.subArticleList !== null && this.item.subArticleList.length > 0);
    }
  }

  hasVoucheredItems(orderArticles: CateringAggregationArticleViewModel[]) {
    return orderArticles.some((article) => !!article.voucherNumber);
  }

  add() {
    if (this._hasAnyMods) {
      this.navigateToDetails();
      return;
    }

    this.updateOrder(this.item, CardActionTypeEnum.ADD);
  }

  increment(article: CateringAggregationArticleViewModel) {
    this.updateOrder(article, CardActionTypeEnum.PATCH_QUANTITY, 1);
  }

  decrement(article: CateringAggregationArticleViewModel) {
    this.updateOrder(article, CardActionTypeEnum.PATCH_QUANTITY, -1);
  }

  delete(article: CateringAggregationArticleViewModel) {
    this.updateOrder(article, CardActionTypeEnum.PATCH_QUANTITY, -1 * article.selectedQuantity);
  }

  navigateToDetails() {
    //this.router.navigate([`/details/${this.item.id}`]);
    this.openModalWithComponent();
  }

  openModalWithComponent() {
    const initialState: ModalOptions = {
      initialState: {
        article: this.item,
        selectedArticleCombinationList: this.item.orderArticles,
      },
    };
    this.bsModalRef = this.modalService.show(ProductDetailsPageComponent, initialState);
    this.bsModalRef.content.closeBtnName = 'Close';
  }

  public modifiers(article: CateringAggregationArticleViewModel): string {
    if (article && article.modifierArticleList) {
      const items: CateringAggregationArticleModifierItemViewModel[] = [];
      article.modifierArticleList.forEach((modifierArticle) => {
        items.push(...modifierArticle.itemCollection);
      });

      //const items = [].concat(article.modifierArticleList.map(modifierArticle => modifierArticle.itemCollection));
      return items.map((o) => (o.quantity > 1 ? `${o.quantity}x ${o.name}` : o.name)).join(', ');
    }

    return '';
  }

  public subarticles(article: CateringAggregationArticleViewModel): string {
    if (article && article.subArticleList) {
      return article.subArticleList
        .map((o) => {
          if (o === null) {
            return '';
          }

          return (o.selectedQuantity ?? 0) > 1 ? `${o.selectedQuantity}x ${o.name}` : o.name;
        })
        .join(', ');
    }
    return '';
  }

  private updateOrder(article: CateringAggregationArticleViewModel, action: CardActionTypeEnum, value?: number) {
    this.articleCardChange.emit(new ArticleCard(article, action, value));
    if (!value) {
      this.cartService.addCart(article);
    } else {
      this.hide = this.cartService.patchQuantity(article, value) === 0;
    }
  }
}
