import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../abstract.view.model';
import { AgreementApiModel } from '../../api-model/agreement/agreement.api.model';

export class AgreementViewModel extends AbstractViewModel<AgreementApiModel> {
  public id: string = null;
  public name: string = null;
  public required: boolean = null;
  public validFrom: DateTime = null;
  public validTo: DateTime = null;
  public priority: number = null;
  public contents: string = null;
  public marketingAgreementGroups: string[] = [];

  public checked = false;
  public valid = true;
  public disabled = false;

  constructor(override apiModel: AgreementApiModel = new AgreementApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name;
    this.required = this.apiModel.required;
    this.validFrom = this.apiModel.validFrom ? DateTime.fromISO(this.apiModel.validFrom) : null;
    this.validTo = this.apiModel.validTo ? DateTime.fromISO(this.apiModel.validTo) : null;
    this.priority = this.apiModel.priority;
    this.contents = this.apiModel.contents;
    this.marketingAgreementGroups = this.apiModel.marketingAgreementGroups;
  }

  toApiModel(): AgreementApiModel {
    return undefined;
  }
}
