import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { OrderDataProvider } from '../../data-providers/order.data-provider';
import { OrderHttpService } from '../../http/order.http.service';
import { OrderStatus } from '../../models/view-model/order/order.view.model';
import { OrderStateService } from '../../services/order.state.service';
import { paymentStatusTypes } from './payment-status-type.model';
import { LoadingService } from '../../components/loading/loading.service';

@Component({
  selector: 'app-payment-page',
  templateUrl: './payment.component.html',
})
export class PaymentPageComponent implements OnInit, OnDestroy {
  public currentTry = 0;
  public maxTriesAmount = 6;

  public status = '_';
  private subscription: Subscription;
  private queryParamsSubscription: Subscription = Subscription.EMPTY;
  public paymentStatusTypes = paymentStatusTypes;

  public cinemaId: string = null;
  public orderId: string = null;
  public orderNumber: string = null;

  constructor(
    private orderStateService: OrderStateService,
    private loadingServide: LoadingService,
    private orderDataProvider: OrderDataProvider,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.blockBack();

    this.status = this.route.snapshot.params['status'] || null;
    this.subscription = this.orderStateService.state$.subscribe((state) => {
      if (state && state.readyForUse) {
        if (this.status === paymentStatusTypes.cancel && state.cinema && state.order) {
          this.orderDataProvider.delete(state.cinema.id, state.order.id).subscribe(() => {
            this.orderStateService.cleanState();
          });
          return;
        }

        this.orderStateService.cleanState();
      }
    });

    this.queryParamsSubscription = this.route.queryParams.subscribe((params) => {
      if (params['cinemaId'] && params['orderId']) {
        this.cinemaId = params['cinemaId'];
        this.orderId = params['orderId'];
      } else if (params['params']) {
        const queryParams = params['params'].split(',');

        if (queryParams.length < 2) {
          return;
        }

        this.orderId = queryParams[0];
        this.cinemaId = queryParams[1];
      }

      if (this.status == null) {
        this.orderStateService.cleanState(true);
        if (this.orderId) {
          this.checkOrderStatus();
        }
      }
    });
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.queryParamsSubscription) {
      this.queryParamsSubscription.unsubscribe();
    }
  }

  private blockBack() {
    history.pushState(null, '', location.href);
    window.onpopstate = function () {
      history.go(1);
    };
  }

  public checkOrderStatus(): void {
    if (!this.cinemaId || !this.orderId) {
      console.error('Invalid data');
      return;
    }

    this.loadingServide.showLoader();
    OrderHttpService.cacheBuster$.next();

    this.orderDataProvider.findById(this.cinemaId, this.orderId).subscribe((orderSimple) => {
      this.currentTry++;

      switch (orderSimple.status) {
        case OrderStatus.FINISHED:
          this.status = paymentStatusTypes.success;
          this.orderNumber = orderSimple.orderNumber;
          this.loadingServide.hideLoader();
          break;
        case OrderStatus.CANCELLED:
          this.status = paymentStatusTypes.cancel;
          this.loadingServide.hideLoader();
          break;
        default:
          this.status = paymentStatusTypes.pending;
          if (this.currentTry < this.maxTriesAmount) {
            setTimeout(() => this.checkOrderStatus(), 5000);
          } else {
            this.loadingServide.hideLoader();
          }
          break;
      }
    });
  }
}
