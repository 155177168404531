import { AbstractViewModel } from '../../abstract.view.model';
import { PaymentApiModel } from '../../api-model/sales-document/payment.api.model';

export class PaymentViewModel extends AbstractViewModel<PaymentApiModel> {
  id: string;
  type: string;
  plainPayload: string;
  provider: string;
  merchantTransactionId: string;

  constructor(protected override apiModel: PaymentApiModel = new PaymentApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.type = this.apiModel.type;
    this.plainPayload = this.apiModel.plainPayload;
    this.provider = this.apiModel.provider;
    this.merchantTransactionId = this.apiModel.merchantTransactionId;
  }

  toApiModel(): PaymentApiModel {
    return undefined;
  }
}
