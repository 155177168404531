import { AbstractViewModel } from '../../abstract.view.model';
import { AgreementAggregationApiModel } from '../../api-model/agreement/agreement-aggregation.api.model';
import { AgreementGroupViewModel } from './agreement-group.view.model';
import { AgreementViewModel } from './agreement.view.model';

export class AgreementAggregationViewModel extends AbstractViewModel<AgreementAggregationApiModel> {
  public marketingAgreements: AgreementViewModel[] = [];
  public marketingAgreementGroups: AgreementGroupViewModel[] = [];

  constructor(override apiModel: AgreementAggregationApiModel = new AgreementAggregationApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.marketingAgreements = this.apiModel.marketingAgreements ? this.apiModel.marketingAgreements.map((x) => new AgreementViewModel(x)) : [];
    this.marketingAgreementGroups = this.apiModel.marketingAgreementGroups
      ? this.apiModel.marketingAgreementGroups.map((x) => new AgreementGroupViewModel(x))
      : [];
  }

  toApiModel(): AgreementAggregationApiModel {
    return undefined;
  }
}
