<div class="qr-scan-header">
  <app-logo></app-logo>
  <p>{{ "app.page.scanner.scan-qr-code" | translate }}</p>
</div>
<div class="qr-scan-area">
  <!-- This is the npm package in-charge of scanning the QR -->
  <zxing-scanner #scanner [(device)]="currentDevice" (scanSuccess)="onCodeResult($event)"
                 (permissionResponse)="onHasPermission($event)"
                 (camerasNotFound)="onCamerasNotFound($event)"></zxing-scanner>
  <div class="qr-area">
    <div class="area"></div>
  </div>
</div>