import { Component, ChangeDetectorRef, OnInit, HostListener, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { DateTime } from 'luxon';
import { RouterOutlet } from '@angular/router';
import { animate, group, query, style, transition, trigger } from '@angular/animations';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { LoadingService, NotificationService, OrderStateService, SwUpdateService, storageKey } from '@dinein/core';

export const slider = trigger('routeAnimations', [
  transition('* => isLeft', slideTo('left')),
  transition('* => isRight', slideTo('right')),
  transition('isRight => *', slideTo('left')),
  transition('isLeft => *', slideTo('right')),
]);

function slideTo(direction) {
  const optional = { optional: true };
  return [
    query(
      ':enter, :leave',
      [
        style({
          position: 'absolute',
          top: 0,
          [direction]: 0,
          width: '100%',
        }),
      ],
      optional
    ),
    query(':enter', [style({ [direction]: '-100%' })]),
    group([
      query(':leave', [animate('600ms ease', style({ [direction]: '100%', opacity: 0 }))], optional),
      query(':enter', [animate('600ms ease', style({ [direction]: '0%', opacity: 1 }))]),
    ]),
  ];
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [slider],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'DineIn Cinestar';
  currentApplicationVersion = environment.appVersion;

  mobileQuery: MediaQueryList;

  footerContent = '';

  currentYear = DateTime.local().year;

  orientationLandscape = false;
  private _mobileQueryListener: () => void;

  subscription = new Subscription();
  translationLoaded = false;

  @HostListener('window:orientationchange', ['$event'])
  onOrientationChange(event) {
    //console.log('window.orientation', window.orientation)
    this.orientationLandscape = window.orientation !== 0;
  }

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private translateService: TranslateService,
    protected swUpdateService: SwUpdateService,
    private notificationService: NotificationService,
    private orderStateService: OrderStateService,
    private loadingService: LoadingService
  ) {
    this.mobileQuery = media.matchMedia('(max-width: 600px)');
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

    translateService.setDefaultLang(environment.globalize.language.default);
    const lang = sessionStorage.getItem(storageKey.lang);
    this.useLanguage(lang ? lang : environment.globalize.language.default);
  }

  ngOnInit() {
    this.detectIOS();
    this.setPreferredTheme();

    this.subscription.add(
      this.orderStateService.orderExpired.subscribe((date) => {
        if (!date) {
          return;
        }

        this.loadingService.hideLoader();
      })
    );

    this.orderStateService.checkOrderIsExpired();

    window.addEventListener('scroll', function () {
      navbarScroll();
    });
    function navbarScroll() {
      const el = document.getElementsByClassName('header-rollup');
      if (el) {
        const header = el[0];
        if (header) {
          if (window.scrollY > 10) {
            header.classList.add('rollup');
          } else {
            header.classList.remove('rollup');
          }
        }
      }
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async useLanguage(locale: string) {
    // await the http request for the translation file
    await this.translateService
      .use(locale)
      .toPromise()
      .then(() => {
        sessionStorage.setItem(storageKey.lang, locale);
      });
    this.translationLoaded = true;
  }

  detectIOS() {
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    // Detects if device is in standalone mode
    const isInStandaloneMode = () => 'standalone' in (window as any).navigator && (window as any).navigator.standalone;

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      setTimeout(() => {
        this.notificationService.showIosInstall();
      });
    }
  }

  setPreferredTheme() {
    const theme = sessionStorage.getItem(storageKey.theme) ?? environment.theme;
    const body = document.body;
    if (!body.classList.contains(theme)) {
      body.classList.add(theme);
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData['animation'];
  }
}
