<div class="row">
    <div class="col-6">
        <mat-form-field appearance="outline">
            <mat-label>{{ 'label.code' | translate }}</mat-label>
            <input matInput [(ngModel)]="number" placeholder="{{
        'label.ex' | translate : { value: '1Q2D348GR743X' }
        }}" autocomplete="off" maxlength="30" [readonly]="readonly" />
        </mat-form-field>
    </div>
    <div class="col-6 submit-btn">
        <button matSuffix mat-raised-button color="primary" (click)="use()" [disabled]="!number?.length || disabled">
            {{ 'button.use' | translate }}
        </button>
    </div>
</div>