import { Component, EventEmitter, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { PaymentProviderEvent } from '../../event/payment-provider.event';
import { PaymentPreInitModel } from '../../model/payment-pre-init.model';
import { PaymentProviderComponentInterface } from '../payment-provider.component.interface';
import { PaymentViewModel } from '../../../../../models/view-model/sales-document/payment.view.model';

@Component({
  selector: 'app-payment-provider-payu-blik-component',
  templateUrl: './payu-blik-payment-provider.component.html',
})
export class PayuBlikPaymentProviderComponent implements PaymentProviderComponentInterface {
  @Output()
  public events: EventEmitter<PaymentProviderEvent> = new EventEmitter<PaymentProviderEvent>();

  public inputCode: string;

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  public onPostInitPayment(paymentModel: PaymentViewModel): void {}

  public onPreInitPayment(event: PaymentPreInitModel): Observable<PaymentPreInitModel> {
    return new Observable<PaymentPreInitModel>((subscriber) => {
      event.paymentData['authorization_code'] = this.inputCode;

      subscriber.next(event);
      subscriber.complete();
    });
  }
}
