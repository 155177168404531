import { Directive, ElementRef, Inject } from '@angular/core';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';

@Directive({ selector: 'img[appLazy]' })
export class ImageLazyDirective {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, { nativeElement }: ElementRef<HTMLImageElement>) {
    const supports = 'loading' in HTMLImageElement.prototype;
    if (supports) {
      nativeElement.setAttribute('loading', 'lazy');
    }
  }
}
