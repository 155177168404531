<div>
  <app-logo></app-logo>
</div>

<mat-card *ngIf="message !== null" class="my-5">
  <mat-card-content>
    <h5 class="group-label">{{message.title}}</h5>

    <div class="px-0 py-3">
      <ng-container [ngSwitch]="id">
        <ng-container *ngSwitchCase="'5000'">
          <p>{{message.content}}</p>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <p>{{message.content}}</p>
        </ng-container>
      </ng-container>
    </div>

    <div class="row">
      <div class="col-12">
        <button class="action-button" mat-raised-button color="primary" (click)="goAction()">
          {{ message.button }}
        </button>
      </div>
    </div>

  </mat-card-content>
</mat-card>