import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Cacheable, LocalStorageStrategy } from 'ts-cacheable';
import { MovieApiModel } from '../models/api-model/movie/movie.api.model';
import { ScreenApiModel } from '../models/api-model/screen/screen.api.model';
import { ScreeningRequestModel } from '../models/request/screening.request.model';
import { ScreeningApiModel } from '../models/api-model/screening/screening.api.model';
import { CinemaApiModel } from '../models/api-model/cinema/cinema.api.model';

@Injectable({
  providedIn: 'root',
})
export class BaseHttpService {
  constructor(private http: HttpClient) {}

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
  })
  getCinemas(): Observable<CinemaApiModel[]> {
    return this.http.get<CinemaApiModel[]>('/cinema');
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
  })
  getCinema(id: string): Observable<CinemaApiModel> {
    return this.http.get<CinemaApiModel>(`/cinema/${id}`);
  }

  @Cacheable({
    storageStrategy: LocalStorageStrategy,
    maxCacheCount: 5,
  })
  getMovie(id: string): Observable<MovieApiModel> {
    return this.http.get<MovieApiModel>(`/movie/${id}`);
  }

  @Cacheable()
  getScreen(cinemaId: string, screenId: string): Observable<ScreenApiModel> {
    return this.http.get<ScreenApiModel>(`/cinema/${cinemaId}/screen/${screenId}`);
  }

  @Cacheable()
  getScreenings(model: ScreeningRequestModel): Observable<ScreeningApiModel[]> {
    return this.http.get<ScreeningApiModel[]>(`/cinema/${model.cinema}/screening`, {
      params: {
        dateTimeFrom: model.dateTimeFrom,
        dateTimeTo: model.dateTimeTo,
      },
    });
  }
}
