<ng-container [ngSwitch]="env.payment.provider">
  <ng-container *ngSwitchCase="'fake'">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="form">
          <h5 class="group-label">{{ 'label.payment' | translate }}</h5>
          <div class="row">
            <div class="col-12">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'label.card-number' | translate }}</mat-label>
                <input matInput formControlName="creditCard" [disabled]="true" mask="9999999999999999" #creditCard
                       placeholder="{{ 'label.ex' | translate : {value:'1234 5678 9012 3456'} }}" value=""
                       autocomplete="off" required />
                <mat-error *ngIf="form.get('creditCard').hasError('required')" [innerHtml]="
                    'app.page.order.credit-card-required' | translate
                  "></mat-error>
                <mat-hint align="end">{{ creditCard.value.length }} / 16</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'label.expiry-date' | translate }}</mat-label>
                <input matInput formControlName="creditCardExpireDate" mask="99/99"
                       placeholder="{{ 'label.ex' | translate : {value:'01/19'} }}" autocomplete="off" required />
              </mat-form-field>
            </div>
            <div class="col-6">
              <mat-form-field appearance="outline">
                <mat-label>{{ 'label.security-code' | translate }}</mat-label>
                <input matInput formControlName="creditCardSecure" mask="999" autocomplete="off" required />
              </mat-form-field>
            </div>
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </ng-container>

  <ng-container *ngSwitchCase="'payu'">
    <mat-card>
      <mat-card-content>
        <div class="payment-provider-area">
          <div class="provider">
            <h5 class="group-label">{{ 'payment.title' | translate }}</h5>
            <ng-container *ngFor="let paymentMethod of paymentMethodList">
              <div class="col-md-12 header p-3 d-none">
                <input type="checkbox" name="paymentMethodSelected" [checked]="
                    selectedPaymentMethod &&
                    paymentMethod.id === selectedPaymentMethod.id
                  " (click)="selectedPaymentMethod = paymentMethod" [disabled]="paymentMethod.disabled" />
                <span class="title">{{ paymentMethod.name }}</span>
              </div>
              <app-payment-proxy-component #paymentProxyComponent [paymentMethod]="paymentMethod"
                                           (providerEvent)="onPaymentProviderEvent($event)">
              </app-payment-proxy-component>
            </ng-container>
          </div>

          <button #buttonElement style="display: none" (click)="payButtonClicked()" type="submit">
            {{ 'payment.button.title' | translate }}
          </button>
        </div>
      </mat-card-content>
    </mat-card>
  </ng-container>

  <ng-container *ngSwitchCase="'worldpay'">
    <mat-card *ngIf="embeddedContent">
      <mat-card-content>
        <iframe #iframe style="width: 100%; min-height: 360px; position: relative"
                [src]="embeddedContent | safe : 'resourceUrl'" frameborder="0" (load)="onLoadIFrame()"></iframe>
      </mat-card-content>
    </mat-card>
  </ng-container>
</ng-container>