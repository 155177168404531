import { Expose, Transform } from 'class-transformer';
import { DateTime } from 'luxon';

export class CardApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  pointBalance: number;

  @Expose()
  valueBalance: number;

  @Expose()
  statusId: number;

  @Expose()
  cardTypeId: string;

  @Expose()
  clientId: string;

  @Expose()
  status: boolean;

  @Expose()
  issueDate: string;

  @Expose()
  salesChannelId: string;

  @Expose()
  cardBatchId: string;

  @Expose()
  flgUseInLocal: number;

  @Expose()
  cinemaId: string;

  @Expose()
  expireDate: string;

  @Expose()
  message: string;

  @Expose()
  templateId: string;

  @Expose()
  isHidden: boolean;

  @Expose()
  type: number;
}
