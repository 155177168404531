import { Expose } from 'class-transformer';
import { CinemaHeadApiModel } from './cinema-head.api.model';

export class CinemaApiModel extends CinemaHeadApiModel {
  @Expose()
  zipcode: string;

  @Expose()
  reservationPhone: string;

  @Expose()
  description: string;

  @Expose()
  url1: string;

  @Expose()
  url2: string;

  @Expose()
  numberOfScreens: number;

  @Expose()
  numberOfSeats: number;

  @Expose()
  numberOfDisabledSeats: number;

  @Expose()
  graphics: string[];

  @Expose()
  email: string;

  @Expose()
  reservationGroupPhone: string;

  @Expose()
  fax: string;

  @Expose()
  isAnyDreamScreen: boolean;

  @Expose()
  timezone: string;
}
