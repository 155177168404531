import { Expose, Type } from 'class-transformer';
import { ScreeningItemExtraFeeApiModel } from './screening-item-extra-fee.api.model';
import { ScreeningItemMembershipPriceApiModel } from './screening-item-membership-price.api.model';

export class ScreeningItemApiModel {
  @Expose()
  id: string;

  @Expose()
  seatId: string;

  @Expose()
  screeningId: string;

  @Expose()
  ticketId: string;

  @Expose()
  price: number;

  @Expose()
  ticketPrice: number;

  @Expose()
  ticketPriceWithMandatoryExtraFees: number;

  @Expose()
  ticketDefaultPriceLevelPriceWithMandatoryExtraFees: number;

  @Expose()
  quantity: number;

  @Expose()
  tax: number;

  @Expose()
  //@Type(() => ScreeningItemExtraFeeApiModel)
  extraFees: ScreeningItemExtraFeeApiModel[] = [];

  @Expose()
  //@Type(() => ScreeningItemMembershipPriceApiModel)
  membershipPrices: ScreeningItemMembershipPriceApiModel[] = [];

  @Expose()
  bookingId: string;

  @Expose()
  cardId: string;

  @Expose()
  optionalExtraFees: string[] = [];

  @Expose()
  name: string;

  @Expose()
  printoutName: string;

  @Expose()
  voucherNumber: string;

  @Expose()
  voucherName: string;

  @Expose()
  defaultPriceLevelPrice: number;

  @Expose()
  ticketDefaultPriceLevelPrice: number;

  @Expose()
  itemEarnedPoints: number;

  @Expose()
  itemRedemptionPoints: number;

  @Expose()
  promotionId: string;

  @Expose()
  promotionName: string;

  @Expose()
  row: string;

  @Expose()
  seat: string;
}
