<div *ngIf="show">
  <div class="backdrop" [ngClass]="{'full-screen' : config?.fullScreenBackdrop === true}"
       [ngStyle]="{'border-radius': config?.backdropBorderRadius, 'background-color': config?.backdropBackgroundColour}">
  </div>

  <div [ngSwitch]="config?.animationType">
    <div class="spinner-circle" *ngSwitchCase="loadingAnimationTypes.circle"
         [ngClass]="{'full-screen' : config?.fullScreenBackdrop === true}"
         [ngStyle]="{'border-top-color': config?.secondaryColour, 'border-right-color': config?.secondaryColour, 'border-bottom-color': config?.secondaryColour, 'border-left-color': config?.primaryColour}">
    </div>

    <div *ngSwitchCase="loadingAnimationTypes.cubeGrid" class="sk-cube-grid"
         [ngClass]="{'full-screen' : config?.fullScreenBackdrop === true}">
      <div class="sk-cube sk-cube1" [ngStyle]="{'background-color': config?.primaryColour}"></div>
      <div class="sk-cube sk-cube2" [ngStyle]="{'background-color': config?.primaryColour}"></div>
      <div class="sk-cube sk-cube3" [ngStyle]="{'background-color': config?.primaryColour}"></div>
      <div class="sk-cube sk-cube4" [ngStyle]="{'background-color': config?.primaryColour}"></div>
      <div class="sk-cube sk-cube5" [ngStyle]="{'background-color': config?.primaryColour}"></div>
      <div class="sk-cube sk-cube6" [ngStyle]="{'background-color': config?.primaryColour}"></div>
      <div class="sk-cube sk-cube7" [ngStyle]="{'background-color': config?.primaryColour}"></div>
      <div class="sk-cube sk-cube8" [ngStyle]="{'background-color': config?.primaryColour}"></div>
      <div class="sk-cube sk-cube9" [ngStyle]="{'background-color': config?.primaryColour}"></div>
    </div>

    <div class="spinner-sk-rotateplane" *ngSwitchCase="loadingAnimationTypes.rotatingPlane"
         [ngStyle]="{'background-color': config?.primaryColour}"
         [ngClass]="{'full-screen' : config?.fullScreenBackdrop === true}"></div>

    <div class="spinner-rectangle-bounce" *ngSwitchCase="loadingAnimationTypes.rectangleBounce"
         [ngClass]="{'full-screen' : config?.fullScreenBackdrop === true}">
      <div class="rect1" [ngStyle]="{'background-color': config?.primaryColour}"></div>
      <div class="rect2" [ngStyle]="{'background-color': config?.primaryColour}"></div>
      <div class="rect3" [ngStyle]="{'background-color': config?.primaryColour}"></div>
      <div class="rect4" [ngStyle]="{'background-color': config?.primaryColour}"></div>
      <div class="rect5" [ngStyle]="{'background-color': config?.primaryColour}"></div>
    </div>

    <div class="spinner-wandering-cubes" *ngSwitchCase="loadingAnimationTypes.wanderingCubes"
         [ngClass]="{'full-screen' : config?.fullScreenBackdrop === true}">
      <div class="cube1" [ngStyle]="{'background-color': config?.primaryColour}"></div>
      <div class="cube2" [ngStyle]="{'background-color': config?.secondaryColour}"></div>
    </div>

    <div class="spinner-double-bounce" *ngSwitchCase="loadingAnimationTypes.doubleBounce"
         [ngClass]="{'full-screen' : config?.fullScreenBackdrop === true}">
      <div class="double-bounce1" [ngStyle]="{'background-color': config?.primaryColour}"></div>
      <div class="double-bounce2" [ngStyle]="{'background-color': config?.secondaryColour}"></div>
    </div>

    <div class="spinner-pulse" *ngSwitchCase="loadingAnimationTypes.pulse"
         [ngClass]="{'full-screen' : config?.fullScreenBackdrop === true}"
         [ngStyle]="{'background-color': config?.primaryColour}"></div>

    <div class="spinner-chasing-dots" *ngSwitchCase="loadingAnimationTypes.chasingDots"
         [ngClass]="{'full-screen' : config?.fullScreenBackdrop === true}">
      <div class="dot1" [ngStyle]="{'background-color': config?.primaryColour}"></div>
      <div class="dot2" [ngStyle]="{'background-color': config?.secondaryColour}"></div>
    </div>

    <div class="spinner-circle-swish" *ngSwitchCase="loadingAnimationTypes.circleSwish"
         [ngClass]="{'full-screen' : config?.fullScreenBackdrop === true}" [ngStyle]="{'color': config?.primaryColour}">
    </div>

    <div *ngSwitchCase="loadingAnimationTypes.none" [ngClass]="{'full-screen' : config?.fullScreenBackdrop === true}">
    </div>

    <div *ngSwitchCase="loadingAnimationTypes.threeBounce" class="spinner-three-bounce"
         [ngClass]="{'full-screen' : config?.fullScreenBackdrop === true}">
      <div class="bounce1" [ngStyle]="{'background-color': config?.primaryColour}"></div>
      <div class="bounce2" [ngStyle]="{'background-color': config?.secondaryColour}"></div>
      <div class="bounce3" [ngStyle]="{'background-color': config?.tertiaryColour}"></div>
    </div>

    <div class="spinner-simple" *ngSwitchDefault>
      <div class="row indicator">
        <div class="col text-center">
          <i class="fas fa-spinner fa-spin fa-4x"></i>
        </div>
      </div>
    </div>

    <ng-container *ngIf="template">
      <ng-container *ngTemplateOutlet="template"></ng-container>
    </ng-container>
  </div>
</div>