export class UseGiftCardRequestModel {
  constructor(cardId: string, typeId: string = null) {
    this.cardId = cardId;
    this.typeId = typeId;
  }

  public cardId: string;

  public typeId: string;
}
