import { AbstractViewModel } from '../../abstract.view.model';
import { ScreenElementApiModel } from '../../api-model/screen/screen-element.api.model';
import { IScreenElementModel } from '../../interfaces';

export class ScreenElementViewModel extends AbstractViewModel<ScreenElementApiModel> implements IScreenElementModel {
  id: string;
  name: string;
  seatIds: string[];

  constructor(protected apiModel: ScreenElementApiModel = new ScreenElementApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.name = this.apiModel.name.toLowerCase();
    this.seatIds = this.apiModel.seatIds ? this.apiModel.seatIds.map((o) => o.toLowerCase()) : [];
  }

  toApiModel(): ScreenElementApiModel {
    return undefined;
  }

  toString(): string {
    return this.name;
  }
}
