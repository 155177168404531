import { Directive, ElementRef, HostListener, Input, Output, EventEmitter } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'input[type=number], input[digitOnly]',
})
export class DigitDirective {
  @Output() valueChange = new EventEmitter();
  @Input() excludepattern = '[^0-9]*';
  constructor(private _el: ElementRef) {}

  @HostListener('input', ['$event']) onInputChange(event: any) {
    this.check(event);
  }
  @HostListener('paste', ['$event']) onPaste(event: any) {
    const clipboardData: DataTransfer = event.clipboardData || (window as any).clipboardData;
    const pastedText: string = clipboardData.getData('text');
    if (pastedText && !String(pastedText).match(this.getPatternRegex())) {
      event.preventDefault();
    }
  }
  @HostListener('keyup', ['$event']) onKeyUp(event: KeyboardEvent) {
    this.check(event);
  }
  private check(event: any) {
    const initalValue = this._el.nativeElement.value;
    const newValue = initalValue.replace(this.getPatternRegex(), '');
    this._el.nativeElement.value = newValue;
    this.valueChange.emit(newValue);
    if (initalValue !== this._el.nativeElement.value) {
      event.stopPropagation();
    }
  }
  private getPatternRegex(): RegExp {
    return new RegExp(this.excludepattern, 'g');
  }
}
