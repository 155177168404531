// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  appVersion: require('../../../../package.json').version + '-dev',
  production: false,
  projectName: 'cinestar',
  brand: {
    fullLogo: '',
    subLogo: '',
  },
  globalize: {
    country: 'CH',
    currency: '',
    language: {
      default: 'en',
      available: ['de', 'en', 'fr'],
      cultureMap: [
        { key: 'de', value: 'de-CH' },
        { key: 'en', value: 'en-CH' },
        { key: 'fr', value: 'fr-CH' },
      ],
    },
  },
  apiUrl: '',
  baseImgOnError: './assets/images/D.svg',
  tips: [
    { type: 'fixed', value: 0 },
    { type: 'percent', value: 5 },
    { type: 'percent', value: 10 },
    { type: 'percent', value: 15 },
    { type: 'manual', value: 0 },
  ],
  payment: {
    provider: 'corvuspay',
    giftcard: '',
    channel: 'web',
    paymentRedirectUrl: '',
    allowedPaymentMethod: ['corvuspay'],
    autoSelectedPaymentMethod: 'corvuspay',
    canUseStoredPaymentMethod: false,
  },
  features: {
    callForService: false,
  },
  rulesUrl: 'https://www.blue.pl/regulamin_sprzedazy/',
  theme: 'dark',
  order: {
    orderAgreementGroupId: '',
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
