import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'quantity',
})
export class QuantityPipe implements PipeTransform {
  transform(value: number, sign: string = 'x'): string | null {
    return value + sign;
  }
}
