import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PaymentProviderStateService } from '../../../service/payment-provider-state.service';
import { PaymentProviderPayMethodViewModel } from '../../../../../models/view-model/payment-provider-pay-method.view.model';

@Component({
  selector: 'app-payment-method',
  templateUrl: './payment-method.component.html',
})
export class PaymentMethodComponent {
  @Input() public paymentMethod: PaymentProviderPayMethodViewModel;
  @Input() public paymentType: string;
  @Input() public paymentProvider: string;
  @Input() public paymentImgUrl: string;

  @Input()
  public set selected(value: boolean) {
    this._selected = value;
  }
  public get selected() {
    return this._selected;
  }

  private _selected = false;

  private paymentMethodWithImages = ['ap', 'blik', 'c', 'jp', 'cinkciarz', 'przelewy24'];

  constructor(private translateService: TranslateService, private selectedPaymentTypeService: PaymentProviderStateService) {}

  public paymentProviderClick() {
    const model = this.selectedPaymentTypeService.getState();
    this.selectedPaymentTypeService.updateOrderState({ ...model, type: this.paymentType, provider: this.paymentProvider });
  }

  get hasImage() {
    return this.paymentMethod && this.paymentMethodWithImages.includes(this.paymentMethod.id.toLocaleLowerCase());
  }

  get properTranslation() {
    if (this.paymentMethod && this.paymentMethod.id.toLocaleLowerCase() === 'jp') {
      return this.translateService.instant('payment.method.applePay');
    }
    return this.translateService.instant('payment.method.' + this.paymentType);
  }
}
