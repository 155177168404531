import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { VoucherService } from '../../services/voucher.service';
import { catchError, iif, mergeMap, of, switchMap, tap } from 'rxjs';
import { CardDataProvider } from '../../data-providers/card.data-provider';
import { OrderViewModel } from '../../models/view-model/order/order.view.model';
import { CinemaViewModel } from '../../models/view-model/cinema/cinema.view.model';
import { OrderStateService } from '../../services/order.state.service';
import { UseGiftCardRequestModel } from '../../models/use-gift-card.request.model';
import { OrderDataProvider } from '../../data-providers/order.data-provider';
import { HttpErrorResponse } from '@angular/common/http';

export enum DiscountGiftStatusEnum {
  BEGIN = 'BEGIN',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  SUCCESS = 'SUCCESS',
}

export interface IDiscountGiftStateModel {
  status: DiscountGiftStatusEnum;
  data?: any;
  error?: any;
}

@Component({
  selector: 'app-promo-code',
  templateUrl: './promo-code.component.html',
})
export class PromoCodeComponent implements OnInit {
  @Input() cinema: CinemaViewModel;
  @Input() order: OrderViewModel;
  @Input() public readonly: boolean;
  @Input() public disabled = false;
  @Input() tryUseGiftCard: boolean;

  @Output() public stateChanged = new EventEmitter<IDiscountGiftStateModel>();

  public number: string = null;

  constructor(
    private voucherService: VoucherService,
    private cardDataProvider: CardDataProvider,
    private orderStateService: OrderStateService,
    private orderDataProvider: OrderDataProvider
  ) {}

  ngOnInit(): void {
    this.number = '';
  }

  public use(): void {
    if (!this.number.length) {
      return;
    }

    this.stateChanged.emit({ status: DiscountGiftStatusEnum.BEGIN, data: null });

    const orderNotExist = this.orderStateService.orderNotExist();
    if (orderNotExist) {
      return;
    }

    this.voucherService
      .assignVoucherToOrderViaApiModel(this.cinema.id, this.order.id, this.number)
      .pipe(
        catchError(() =>
          iif(
            () => this.tryUseGiftCard === true,
            this.cardDataProvider.info(this.number).pipe(
              mergeMap((cardInfo) => {
                const request = this.order.paymentMethods.filter((x) => x.cardId).map((x) => new UseGiftCardRequestModel(x.cardId));
                request.push(new UseGiftCardRequestModel(cardInfo.id));
                return this.cardDataProvider.useGiftCard(this.cinema.id, this.order.id, request);
              }),
              tap((res) => {
                this.orderStateService.setOrder(res);
                this.number = '';
              }),
              switchMap((res) => {
                return this.orderDataProvider.closeBasket(this.cinema.id, res.id, false);
              })
            ),
            of(this.order)
          )
        )
      )
      .subscribe({
        next: (data) => {
          this.stateChanged.emit({ status: DiscountGiftStatusEnum.SUCCESS, data: data });
        },
        error: (error: HttpErrorResponse) => {
          this.stateChanged.emit({ status: DiscountGiftStatusEnum.FAILED, error: error });
        },
      });
  }
}
