import { Inject, Injectable } from '@angular/core';
import { plainToInstance } from 'class-transformer';
import { Observable, map } from 'rxjs';
import { UserHttpService } from '../http/user.http.service';
import { UserTokenRequestModel } from '../models/request/user-token.request.model';
import { UserLoginRequestModel } from '../models/request/user-login.request.model';
import { UserLoginResponseModel } from '../models/response/user-login.response.model';
import { UserAuthRequestModel } from '../models/request/user-auth.request.model';
import { UserTokenResponseModel } from '../models/response/user-token.response.model';
import { HttpHeaders } from '@angular/common/http';
import { UserApiModel } from '../models/api-model/user/user.api.model';
import { AccountItemsViewModel } from '../models/view-model/account-items/account-items.view.model';
import { AccountItemsApiModel } from '../models/api-model/account-items/account-items.api.model';
import { AgreementAggregationViewModel } from '../models/view-model/agreement/agreement-aggregation.view.model';
import { UserAgreementHttpService } from '../http/user.agreement.http.service';
import { AgreementType } from '../models/view-model/agreement/agreement-type.enum';
import { AgreementAggregationApiModel } from '../models/api-model/agreement/agreement-aggregation.api.model';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';

@Injectable({
  providedIn: 'root',
})
export class UserDataProvider {
  constructor(
    @Inject(ENVIRONMENT_TOKEN) protected environment,
    private userHttpService: UserHttpService,
    private userAgreementHttpService: UserAgreementHttpService
  ) {}

  public get(token: string): Observable<UserApiModel> {
    const params = {
      headers: new HttpHeaders({
        Authorization: `Bearer ${token}`,
      }),
    };

    return this.userHttpService.getCurrent(params);
  }

  public login(request): Observable<UserLoginResponseModel> {
    return this.userHttpService.login(plainToInstance(UserLoginRequestModel, request as any));
  }

  public loginByToken(request): Observable<UserTokenResponseModel> {
    return this.userHttpService.loginByToken(plainToInstance(UserTokenRequestModel, request as any));
  }

  public doAuth(authProvider: string, token: string, state: string | null = null, redirectUri: string | null = null): Observable<UserLoginResponseModel> {
    return this.userHttpService.doAuth(
      authProvider,
      plainToInstance(UserAuthRequestModel, {
        token: token,
        state: state,
        redirect_uri: redirectUri,
      } as any)
    );
  }

  public doAuthByToken(authProvider: string, token: string, state: string = null): Observable<UserTokenResponseModel> {
    return this.userHttpService.doAuthByToken(authProvider, token, state);
  }

  public getAuthRedirect(authProvider: string, redirectUrl: string): Observable<string> {
    return this.userHttpService.getAuthRedirect(authProvider, redirectUrl);
  }

  public getAccountItemsViaApiModel(): Observable<AccountItemsViewModel> {
    return this.userHttpService.getAccountItemsViaApiModel().pipe(
      map((result) => plainToInstance(AccountItemsApiModel, result as any)),
      map((result) => new AccountItemsViewModel(result))
    );
  }

  public agreementGet(agreementType: AgreementType = AgreementType.ALL): Observable<AgreementAggregationViewModel> {
    const source = this.userAgreementHttpService.listViaApiModel();

    if (agreementType === AgreementType.ALL) {
      return source.pipe(map((result: AgreementAggregationApiModel) => new AgreementAggregationViewModel(result)));
    }

    return source.pipe(
      map((aggrements) => {
        const orderAgreementID = this.environment.order.orderAgreementGroupId;

        switch (agreementType) {
          case AgreementType.COMMON:
            aggrements.marketingAgreements = aggrements.marketingAgreements.filter((o) => !o.marketingAgreementGroups.includes(orderAgreementID));
            break;
          case AgreementType.ORDER:
            aggrements.marketingAgreements = aggrements.marketingAgreements.filter((o) => o.marketingAgreementGroups.includes(orderAgreementID));
            break;
        }

        aggrements.marketingAgreementGroups = aggrements.marketingAgreementGroups.filter((g) =>
          aggrements.marketingAgreements.reduce((x, y) => [...x, ...y.marketingAgreementGroups], []).includes(g.id)
        );

        return new AgreementAggregationViewModel(aggrements);
      })
    );
  }
}
