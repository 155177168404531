import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { OrderHttpService } from '../http/order.http.service';
import { OrderFbItemHttpService } from '../http/order-fbitem.http.service';
import { OrderViewModel } from '../models/view-model/order/order.view.model';
import { FbItemViewModel } from '../models/view-model/order/fb-item/fb-item.view.model';
import { CateringModel } from '../models/catering.model';
import { forkJoin } from 'rxjs';
import { OrderPaymentRequestModel } from '../models/order-payment.request.model';
import { PaymentViewModel } from '../models/view-model/sales-document/payment.view.model';
import { PaymentMethodViewModel } from '../models/view-model/order/payment-method/payment-method.view.model';
import { PaymentProviderConfigRequestModel } from '../models/request/payment-provider-config.request.model';
import { PaymentConfigViewModel } from '../models/view-model/payment/config/payment.config.view.model';
import { PaymentProviderPayMethodApiModel } from '../models/api-model/payment-provider-pay-method.response.model';
import { PaymentProviderPayMethodViewModel } from '../models/view-model/payment-provider-pay-method.view.model';
import { PaymentProviderPayMethodRequestModel } from '../models/request/payment-provider-pay-method.request.model';
import { OrderStateModel } from '../models/order.state.model';
import { PaymentMethodResponseModel } from '../models/response/payment-method.response.model';
import { PaymentMethodApiModel } from '../models/api-model/order/payment-method/payment-method.api.model';

@Injectable({
  providedIn: 'root',
})
export class OrderDataProvider {
  constructor(private httpService: OrderHttpService, private orderFbItemHttpService: OrderFbItemHttpService) {}

  findById(cinemaId: string, orderId: string): Observable<OrderViewModel> {
    return this.httpService.findById(cinemaId, orderId).pipe(map((apiModel) => new OrderViewModel(apiModel)));
  }

  closeBasket(cinemaId: string, orderId: string, forcePaymentConfirmed: boolean) {
    return this.httpService.closeBasket(cinemaId, orderId, forcePaymentConfirmed);
  }

  create(cinemaId: string, sourceOrderId: string = null): Observable<OrderViewModel> {
    return this.httpService.create(cinemaId, sourceOrderId).pipe(map((apiModel) => new OrderViewModel(apiModel)));
  }

  update(orderState: OrderStateModel): Observable<OrderViewModel> {
    return this.httpService.update(orderState.cinemaId, orderState.order.toApiModel()).pipe(map((apiModel) => new OrderViewModel(apiModel)));
  }

  delete(cinemaId: string, orderId: string): Observable<void> {
    return this.httpService.delete(cinemaId, orderId).pipe(map(() => null));
  }

  silentDelete(cinemaId: string, order: OrderViewModel): boolean {
    return this.httpService.silentDelete(cinemaId, order.id);
  }

  /**
   * Patches catering items into order
   */
  patchCateringItems(cinemaId: string, items: Array<FbItemViewModel>, order: OrderViewModel | null = null): Observable<OrderViewModel> {
    if (order === null) {
      return this.create(cinemaId).pipe(
        mergeMap((o) => {
          return this.orderFbItemHttpService
            .put(
              cinemaId,
              o.id,
              items.map((item) => item.toApiModel())
            )
            .pipe(map((res) => new OrderViewModel(res)));
        })
      );
    }

    return this.orderFbItemHttpService
      .put(
        cinemaId,
        order.id,
        items.map((item) => item.toApiModel())
      )
      .pipe(map((res) => new OrderViewModel(res)));
  }

  /**
   * Post catering item into order
   */
  postCateringItem(cinemaId: string, orderId: string, item: FbItemViewModel): Observable<OrderViewModel> {
    return this.orderFbItemHttpService.post(cinemaId, orderId, item.toApiModel()).pipe(map((res) => new OrderViewModel(res)));
  }

  /**
   * Patch catering item into order
   */
  patchCateringItem(cinemaId: string, orderId: string, basketItemId: string, item: FbItemViewModel): Observable<OrderViewModel> {
    return this.orderFbItemHttpService.patchItem(cinemaId, orderId, basketItemId, item.toApiModel()).pipe(map((res) => new OrderViewModel(res)));
  }

  patchQuantityCateringItem(cinemaId: string, orderId: string, basketItemId: string, quantity: number): Observable<OrderViewModel> {
    return this.orderFbItemHttpService.patchQuantityItem(cinemaId, orderId, basketItemId, quantity).pipe(map((res) => new OrderViewModel(res)));
  }

  deleteCateringItem(cinemaId: string, orderId: string, itemId: string) {
    return this.orderFbItemHttpService.delete(cinemaId, orderId, itemId).pipe(map((res) => new OrderViewModel(res)));
  }

  getPaymentMethodList(cinemaId: string, orderId: string) {
    return this.httpService.getPaymentMethodList(cinemaId, orderId).pipe(
      map((paymentMethodResponseModels: Array<PaymentMethodResponseModel>) => {
        return paymentMethodResponseModels.map((paymentMethodResponseModel) => new PaymentMethodViewModel(paymentMethodResponseModel as PaymentMethodApiModel));
      })
    );
  }

  getPayment(orderPaymentRequest: OrderPaymentRequestModel) {
    return this.httpService.getPayment(orderPaymentRequest).pipe(map((response) => new PaymentViewModel(response)));
  }

  registerPayment(cinemaId: string, orderId: string, token: string = null) {
    return this.httpService.registerPayment(cinemaId, orderId, token);
  }

  getPaymentProviderConfig(request: PaymentProviderConfigRequestModel): Observable<PaymentConfigViewModel> {
    return this.httpService.getPaymentProviderConfig(request).pipe(map((x) => new PaymentConfigViewModel(x)));
  }

  getPaymentProviderPayMethodCollection(request: PaymentProviderPayMethodRequestModel): Observable<Array<PaymentProviderPayMethodViewModel>> {
    return this.httpService
      .getPaymentProviderPayMethodCollection(request)
      .pipe(map((models: PaymentProviderPayMethodApiModel[]) => models.map((model) => new PaymentProviderPayMethodViewModel(model))));
  }

  /**
   * Gets available catering for order
   */
  getCatering(cinemaId: string, orderId: string = null, screenGroupId: string = null): Observable<CateringModel> {
    return forkJoin({
      nutritionals: this.httpService.getNutritionalInfo(cinemaId),
      catering: this.httpService.getCatering(cinemaId, orderId, screenGroupId),
    }).pipe(map((x) => new CateringModel(x.catering, x.nutritionals)));
  }

  public removeItem(cinemaId: string, orderId: string, itemId: string): Observable<OrderViewModel> {
    return this.httpService.removeItem(cinemaId, orderId, itemId).pipe(map((x) => new OrderViewModel(x)));
  }

  updateAgreements(cinemaId: string, orderId: string, agreements: string[]) {
    return this.httpService.updateAgreement(cinemaId, orderId, agreements);
  }

  public removeExternalPaymentMethod(request: PaymentProviderPayMethodRequestModel): Observable<any> {
    return this.httpService.removeExternalPaymentMethod(request);
  }
}
