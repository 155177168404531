import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import * as locales from 'ngx-bootstrap/locale';
import { defineLocale } from 'ngx-bootstrap/chronos';
import {
  CoreLibraryModule,
  WrapMissingTranslationHandler,
  SocialAuthServiceConfig,
  JwtTokenInterceptor,
  JwtRefreshTokenInterceptor,
  AcceptLanguageInterceptor,
  RequestInterceptor,
  ResponseInterceptor,
} from '@dinein/core';
import { RouterModule } from '@angular/router';
import { environment } from '../environments/environment';
import { CinestarOrderPageComponent } from './pages/order/order.component';

function defineLocales() {
  for (const locale in locales) {
    defineLocale(locales[locale].abbr, locales[locale]);
  }
}
defineLocales();

export function TranslateLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json?t=' + Math.floor(new Date().getTime() / 3600000));
}

export function SocialAuthServiceConfigFactory() {
  return {
    autoLogin: false,
    providers: [],
    onError: (err) => {
      console.error(err);
    },
  } as SocialAuthServiceConfig;
}

@NgModule({
  declarations: [AppComponent, CinestarOrderPageComponent],
  imports: [
    BrowserModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: TranslateLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useClass: WrapMissingTranslationHandler,
      },
    }),
    BrowserAnimationsModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: true,
    }),
    CoreLibraryModule.forRoot(environment, [
      {
        provide: 'SocialAuthServiceConfig',
        useFactory: SocialAuthServiceConfigFactory,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtTokenInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: JwtRefreshTokenInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: AcceptLanguageInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: RequestInterceptor,
        multi: true,
      },
      {
        provide: HTTP_INTERCEPTORS,
        useClass: ResponseInterceptor,
        multi: true,
      },
    ]),
  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
