import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NotificationService } from '../../services/notification.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-scanner',
  templateUrl: './scanner.component.html',
})
export class ScannerComponent {
  availableDevices: MediaDeviceInfo[];
  currentDevice: MediaDeviceInfo = null;
  hasDevices: boolean;
  hasPermission: boolean;

  constructor(private router: Router, private notificationService: NotificationService, private translateService: TranslateService) {}

  onCodeResult(code: string): void {
    if (code && code.length) {
      const parsedString = this.parseQR(code);
      if (parsedString) {
        window.location.href = `${parsedString}`;
        return;
      }

      this.notificationService.showError(this.translateService.instant('app.page.scanner.incorrect-qr'));
    }
  }

  onHasPermission(has: boolean): void {
    this.hasPermission = has;
    if (!has) {
      this.notificationService.showError(this.translateService.instant('app.page.scanner.no-access'));
      this.navigateToSplash();
    }
  }

  onCamerasNotFound(result: any): void {
    this.notificationService.showError(this.translateService.instant('app.page.scanner.device-not-found'));
    this.navigateToSplash();
  }

  parseQR(qrString: string): string {
    const matchArray: RegExpMatchArray = qrString.match(/(\/)[-a-f\d]{36}/g);
    return matchArray && matchArray.length ? matchArray.map((item) => item).join('') : '';
  }

  navigateToSplash() {
    setTimeout(() => {
      this.router.navigate(['']);
    }, 3000);
  }
}
