import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, mergeMap, tap } from 'rxjs/operators';
import { CardHttpService } from '../http/card.http.service';
import { OrderHttpService } from '../http/order.http.service';
import { UseGiftCardRequestModel } from '../models/use-gift-card.request.model';
import { OrderViewModel } from '../models/view-model/order/order.view.model';
import { OrderDataProvider } from './order.data-provider';
import { CardTypeViewModel } from '../models/view-model/card/card-type.view.model';
import { UserDataProvider } from './user.data-provider';
import { CardTypeApiModel } from '../models/api-model/card/card-type.api.model';
import { CardViewModel } from '../models/view-model/card/card.view.model';
import { CardApiModel } from '../models/api-model/card/card.api.model';

@Injectable({
  providedIn: 'root',
})
export class CardDataProvider {
  constructor(private cardHttpService: CardHttpService, private orderDataProvider: OrderDataProvider, private userDataProvider: UserDataProvider) {}

  info(searchValue: string): Observable<CardViewModel> {
    return this.cardHttpService.info(searchValue).pipe(map((result: CardApiModel) => new CardViewModel(result)));
  }

  public useGiftCard(cinemaId: string, orderId: string, request: UseGiftCardRequestModel[]): Observable<OrderViewModel> {
    return this.cardHttpService.useGiftCard(cinemaId, orderId, request).pipe(
      tap(() => OrderHttpService.cacheBuster$.next()),
      mergeMap(() => this.orderDataProvider.findById(cinemaId, orderId))
    );
  }

  public types(cinemaId: string = null): Observable<CardTypeViewModel[]> {
    return this.cardHttpService.types(cinemaId).pipe(map((result: CardTypeApiModel[]) => result.map((type) => new CardTypeViewModel(type))));
  }

  public assignCardToOrder(cinemaId: string, orderId: string, cardId: string): Observable<OrderViewModel> {
    return this.cardHttpService.assignCardToOrder(cinemaId, orderId, cardId).pipe(map((order) => new OrderViewModel(order)));
  }
}
