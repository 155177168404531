import { Expose } from 'class-transformer';

export class SeatGroupApiModel {
  @Expose()
  seatIds: string[];

  @Expose()
  id: string;

  @Expose()
  ico: string;

  @Expose()
  color: string;

  @Expose()
  name: string;

  @Expose()
  description: string;

  @Expose()
  type: string;

  @Expose()
  displayMode: number;

  @Expose()
  salesMode: number;
}
