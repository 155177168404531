import { EventEmitter, Injectable } from '@angular/core';
import { CardElementRefServiceInterface } from './card-element-ref.service.interface';

@Injectable({
  providedIn: 'root',
})
export class CardElementRefService implements CardElementRefServiceInterface {
  createCardOptions(elRef: HTMLElement) {}

  getPayButtonClickEmitter(): EventEmitter<any> {
    return undefined;
  }

  showPaymentMethod(index: number): void {}
}
