<div class="row pay-method-container px-3">
  <div class="col-12">
    <div class="row d-block" *ngIf="getCardPayMethod()">
      <div class="col-12 pay-method-panel">
        <app-payment-method [paymentProvider]="paymentProviderIdentifier" [paymentType]="paymentMethod.TYPE_CARD"
                            [paymentMethod]="getCardPayMethod()"
                            [selected]="selectedPayMethodTypeCustom === paymentMethod.TYPE_CARD">
          <div class="panel-body mt-4" *ngIf="selectedPayMethodTypeCustom === paymentMethod.TYPE_CARD">
            <div class="card-container">
              <div class="card m-3" [ngClass]="{
                  'card-selected': selectedCardMethod === payMethod
                }" *ngFor="let payMethod of cardMethodCollection" (click)="onClickedCardMethod(payMethod)">
                <img [src]="payMethod.imageUrl" />
                <p>{{ payMethod.cardBrand }}</p>
                <p>{{ payMethod.cardMask }}</p>
                <p>
                  {{ payMethod.cardExpirationMonth }}/{{
                    payMethod.cardExpirationYear
                  }}
                </p>
              </div>
            </div>
            <div [formGroup]="cardFormGroup" class="row flex-column d-block">
              <div class="col">
                <mat-form-field appearance="outline">
                  <mat-label>Numer karty</mat-label>
                  <input matInput formControlName="cardNumber" mask="0000 0000 0000 0000" autocomplete="off"
                         inputmode="numeric" />
                </mat-form-field>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col-7">
                    <mat-form-field appearance="outline">
                      <mat-label>Data ważności</mat-label>
                      <input matInput formControlName="cardExpirationDate" mask="00/0099" autocomplete="off"
                             (change)="splitExpirationDate()" inputmode="numeric" />
                    </mat-form-field>
                  </div>
                  <div class="col-5">
                    <mat-form-field appearance="outline">
                      <mat-label>CVV/CVV2</mat-label>
                      <input matInput formControlName="cardCVV" mask="0009" autocomplete="off" inputmode="numeric" />
                    </mat-form-field>
                  </div>
                </div>
              </div>
              <div class="col" *ngIf="canDisplayErrorGroup">
                <div *ngIf="canDisplayError('cardNumber')" class="invalid-feedback d-inline-block">
                  Numer karty musi zawierać 16 znaków
                </div>
                <div *ngIf="canDisplayError('cardCVV')" class="invalid-feedback d-inline-block">
                  CVV musi zawierać 3-4 znaki
                </div>
                <div *ngIf="canDisplayError('cardExpirationDate')" class="invalid-feedback d-inline-block">
                  Wprowadzono błędną datę
                </div>
              </div>
            </div>
          </div>
        </app-payment-method>
      </div>
    </div>
    <div style="visibility: hidden; height: 1px">
      <input type="text" class="payu-card-number" [(ngModel)]="cardModel.cardNumber" />
      <input type="text" class="payu-card-cvv" [(ngModel)]="cardModel.cardCVV" />
      <input type="text" class="payu-card-expm" [(ngModel)]="cardModel.cardExpirationMonth" />
      <input type="text" class="payu-card-expy" [(ngModel)]="cardModel.cardExpirationYear" />
    </div>
    <div class="row d-block" *ngIf="getBLIKPayMethod()">
      <div class="col-12 pay-method-panel">
        <app-payment-method [paymentMethod]="getBLIKPayMethod()" [paymentType]="paymentMethod.TYPE_BLIK"
                            [paymentProvider]="paymentProviderIdentifier"
                            [selected]="selectedPayMethodTypeCustom === paymentMethod.TYPE_BLIK">
          <div class="panel-body mt-4" *ngIf="selectedPayMethodTypeCustom === paymentMethod.TYPE_BLIK">
            <div class="t6-container">
              <div class="form-group mb-0 required row d-block" [formGroup]="form">
                <div class="offset-3 col-6">
                  <mat-form-field appearance="outline">
                    <mat-label>KOD BLIK</mat-label>
                    <input matInput formControlName="blikCode" id="blik_t6" data-charset="_X_ X_X" placeholder="___ ___"
                           mask="000 000" autocomplete="off" class="text-center" />
                  </mat-form-field>
                  <ng-container *ngIf="(form.touched && form.dirty) || formSubmitAttempt">
                    <span *ngIf="
                        !form.get('blikCode').valid &&
                        !form.get('blikCode')?.errors['minLength'] === true
                      " class="invalid-feedback d-inline-block">BLIK musi mieć 6 znaków</span>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </app-payment-method>
      </div>
    </div>
    <div class="row d-block" *ngIf="payByLinkMethodCollection && payByLinkMethodCollection.length > 0">
      <div class="col-12 pay-method-panel">
        <app-payment-method [paymentType]="paymentMethod.TYPE_PBL" [paymentProvider]="paymentProviderIdentifier"
                            [paymentImgUrl]="getDefaultPaymentImageUrl"
                            [selected]="selectedPayMethodTypeCustom === paymentMethod.TYPE_PBL">
          <div class="panel-body" *ngIf="selectedPayMethodTypeCustom === paymentMethod.TYPE_PBL">
            <div class="row pay-by-link-container d-block px-3">
              <div class="pay-by-link mr-3 mt-3" [ngClass]="{ selected: selectedPayByLinkMethod === payMethod }"
                   *ngFor="let payMethod of payByLinkMethodCollection" (click)="onClickedPayByLinkMethod(payMethod)">
                <img [src]="payMethod.imageUrl" />
              </div>
            </div>
          </div>
        </app-payment-method>
      </div>
    </div>
    <div class="row d-block" *ngIf="getGooglePayPayMethod()">
      <div class="col-12 pay-method-panel">
        <app-payment-method [paymentMethod]="getGooglePayPayMethod()" [paymentType]="paymentMethod.TYPE_GPAY"
                            [paymentProvider]="paymentProviderIdentifier"
                            [selected]="selectedPayMethodTypeCustom === paymentMethod.TYPE_GPAY">
          <div class="panel-body" *ngIf="selectedPayMethodTypeCustom === paymentMethod.TYPE_GPAY">
            <div class="t6-container"></div>
          </div>
        </app-payment-method>
      </div>
    </div>
    <div class="row d-block" *ngIf="getApplePayPayMethod()">
      <div class="col-12 pay-method-panel">
        <app-payment-method [paymentMethod]="getApplePayPayMethod()" [paymentProvider]="paymentProviderIdentifier"
                            [selected]="selectedPayMethodTypeCustom === paymentMethod.TYPE_APAY"
                            [paymentType]="paymentMethod.TYPE_APAY">
          <div class="panel-body" *ngIf="selectedPayMethodTypeCustom === paymentMethod.TYPE_APAY">
            <div class="t6-container"></div>
          </div>
        </app-payment-method>
      </div>
    </div>
  </div>
</div>