import { Expose } from 'class-transformer';

export class CardTypeTypeApiModel {
  @Expose()
  number: number;

  @Expose()
  name: string;

  @Expose()
  description: string;
}
