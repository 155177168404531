import { Inject, Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ENVIRONMENT_TOKEN } from '../injection.tokens';

@Injectable()
export class RequestInterceptor implements HttpInterceptor {
  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.indexOf('.json') === -1) {
      req = req.clone({ url: this.environment.apiUrl + req.url });
    }

    return next.handle(req);
  }
}
