import { Injectable } from '@angular/core';
import { HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { storageKey } from '../app.consts';

@Injectable({
  providedIn: 'root',
})
export class AcceptLanguageInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    const lang = sessionStorage.getItem(storageKey.lang);

    if (lang !== null) {
      req = req.clone({
        headers: req.headers.set('Accept-Language', lang),
      });
    }

    return next.handle(req);
  }
}
