import { AbstractViewModel } from '../abstract.view.model';
import { BannerApiModel } from '../api-model/banner.api.model';

export class BannerViewModel extends AbstractViewModel<BannerApiModel> {
  resourceUrl: string;
  targetUrl: string;

  constructor(protected override apiModel: BannerApiModel = new BannerApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.resourceUrl = this.apiModel.resourceUrl;
    this.targetUrl = this.apiModel.targetUrl;
  }

  toApiModel(): BannerApiModel {
    return undefined;
  }
}
