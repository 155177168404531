import { AbstractControl, FormArray, ValidatorFn } from '@angular/forms';

export abstract class ValidatorHelper {
  public static emailPattern = '^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$';
  public static phoneRegex = /^\(?([0-9]{3})\)?[ ]?([0-9]{3})[ ]?([0-9]{3})$/;
}

export function requiredFormArray(): ValidatorFn {
  return (fa: AbstractControl): { [key: string]: any } | null => {
    let valid = true;
    for (let x = 0; x < (fa as FormArray).length; ++x) {
      const checkBoxControl = (fa as FormArray).at(x).value;
      if (checkBoxControl.required && !checkBoxControl.checked) {
        valid = false;
        break;
      }
    }
    return valid ? null : { isValid: valid };
  };
}
