import { AbstractViewModel } from '../../abstract.view.model';
import { VoucherPositionApiModel } from '../../api-model/voucher/voucher-position.api.model';

export class VoucherPositionViewModel extends AbstractViewModel<VoucherPositionApiModel> {
  ticketId: string;
  ticketName: string;
  articleId: string;
  articleName: string;
  price: number;
  newPrice: number;
  discount: number;

  constructor(protected apiModel: VoucherPositionApiModel = new VoucherPositionApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.ticketId = this.apiModel.ticketId;
    this.ticketName = this.apiModel.ticketName;
    this.articleId = this.apiModel.articleId;
    this.articleName = this.apiModel.articleName;
    this.price = this.apiModel.price;
    this.newPrice = this.apiModel.newPrice;
    this.discount = this.apiModel.discount;
  }

  toApiModel(): VoucherPositionApiModel {
    return undefined;
  }
}
