import { DateTime } from 'luxon';
import { AbstractViewModel } from '../../../abstract.view.model';
import { FbItemApiModel } from '../../../api-model/order/fb-item/fb-item.api.model';
import { NutritionalInfoModel } from '../../../nutritional-info.response.model';
import { FbItemMembershipPriceViewModel } from './fb-item-membership-price.view.model';
import { FbItemModifierItemViewModel } from './fb-item-modifier-item.view.model';
import { FbItemSubArticleViewModel } from './fb-item-sub-article.view.model';

export class FbItemViewModel extends AbstractViewModel<FbItemApiModel> {
  id: string;
  articleId: string;
  quantity: number | null;
  voucherNumber: string;
  voucherName: string;
  name: string;
  comboMinPrice?: number;
  price: number;
  taxValue: number;
  pickupTime: DateTime | null;
  defaultPriceLevelPrice: number;
  subArticleList: FbItemSubArticleViewModel[] = [];
  modifierItemList: FbItemModifierItemViewModel[] = [];
  itemEarnedPoints: number;
  itemRedemptionPoints: number;
  promotionId: string;
  promotionName: string;
  membershipPrices: FbItemMembershipPriceViewModel[] = [];
  graphicUrl: string;

  nutritionalInfo?: NutritionalInfoModel;

  //parentGroupId?: string;

  constructor(protected override apiModel: FbItemApiModel = new FbItemApiModel()) {
    super(apiModel);
    this.fromApiModel();
  }

  protected fromApiModel(): void {
    this.id = this.apiModel.id;
    this.articleId = this.apiModel.articleId;
    this.quantity = this.apiModel.quantity;
    this.voucherNumber = this.apiModel.voucherNumber;
    this.voucherName = this.apiModel.voucherName;
    this.name = this.apiModel.name;
    this.price = this.apiModel.price;
    this.comboMinPrice = this.apiModel.comboMinPrice;
    this.taxValue = this.apiModel.taxValue;
    this.pickupTime = this.apiModel.pickupTime ? DateTime.fromISO(this.apiModel.pickupTime) : null;
    this.defaultPriceLevelPrice = this.apiModel.defaultPriceLevelPrice;
    this.subArticleList = this.apiModel.subArticleList ? this.apiModel.subArticleList.map((subArticle) => new FbItemSubArticleViewModel(subArticle)) : [];
    this.modifierItemList = this.apiModel.modifierItemList
      ? this.apiModel.modifierItemList.map((modifierItem) => new FbItemModifierItemViewModel(modifierItem))
      : [];
    this.itemEarnedPoints = this.apiModel.itemEarnedPoints;
    this.itemRedemptionPoints = this.apiModel.itemRedemptionPoints;
    this.promotionId = this.apiModel.promotionId;
    this.promotionName = this.apiModel.promotionName;
    this.membershipPrices = this.apiModel.membershipPrices
      ? this.apiModel.membershipPrices.map((membershipPrice) => new FbItemMembershipPriceViewModel(membershipPrice))
      : [];
    this.graphicUrl = this.apiModel.graphicUrl;
  }

  toApiModel(): FbItemApiModel {
    return Object.assign(this.apiModel, {
      articleId: this.articleId,
      quantity: this.quantity,
      subArticleList: this.subArticleList ? this.subArticleList.map((subArticle) => subArticle.toApiModel()) : [],
      modifierItemList: this.modifierItemList ? this.modifierItemList.map((modifierItem) => modifierItem.toApiModel()) : [],
    });
  }

  hasVoucher() {
    return Boolean(this.voucherNumber) || Boolean(this.voucherName);
  }
}
