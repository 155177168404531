import { Expose } from 'class-transformer';

export class CinemaHeadApiModel {
  @Expose()
  id: string;

  @Expose()
  name: string;

  @Expose()
  groupId: string;

  @Expose()
  city: string;

  @Expose()
  province: string;

  @Expose()
  street: string;

  @Expose()
  latitude: number;

  @Expose()
  longitude: number;

  @Expose()
  phone: string;
}
