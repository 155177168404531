import { Expose } from 'class-transformer';

export class CardTypeMembershipUpgradeFeeApiModel {
  @Expose()
  feeId: string;

  @Expose()
  name: string;

  @Expose()
  extensionTime: number;

  @Expose()
  extensionType: number;

  @Expose()
  feeValue: number;

  @Expose()
  upgradeToCardTypeId: string;

  @Expose()
  upgradeToCardTypeName: string;
}
