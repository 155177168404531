import { Expose } from 'class-transformer';

export class SeatApiModel {
  @Expose()
  id: string;

  @Expose()
  symbol: string;

  @Expose()
  kind: string;

  @Expose()
  colId: string;

  @Expose()
  rowId: string;

  @Expose()
  groupId: string;

  @Expose()
  wheelchairSeat: boolean;
}
