import { Expose, Type } from 'class-transformer';
import { CardApiModel } from './card/card.api.model';
//import { ReservationApiModel } from './reservation/reservation.api.model';
//import { AccountItemsVoucherApiModel } from './voucher/account-items-voucher.api.model';

export class AccountItemsApiModel {
  // @Expose()
  // reservations: ReservationApiModel[];

  // @Expose()
  // vouchers: AccountItemsVoucherApiModel[];

  @Expose()
  cards: CardApiModel[];
}
