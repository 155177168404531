import { Expose, Type } from 'class-transformer';
import { TagApiModel } from './tag.api.model';

export class TagGroupApiModel {
  @Expose()
  symbol: string;

  @Expose()
  description: string;

  @Expose()
  //@Type(() => TagApiModel)
  tags: TagApiModel[];
}
