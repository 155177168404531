import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CreatePaymentRedirectUrlDto } from './dto/create-payment-redirect-url.dto';
import { ENVIRONMENT_TOKEN } from '../../../injection.tokens';

@Injectable({
  providedIn: 'root',
})
export class PaymentHelperService {
  private paymentRedirectUrlPattern: string;

  constructor(@Inject(ENVIRONMENT_TOKEN) protected environment: any, private router: Router) {
    this.paymentRedirectUrlPattern = environment.payment.paymentRedirectUrl;
  }

  /**
   * Creates an payment redirect URI
   * @param request
   */
  public createPaymentRedirectUrl(request: CreatePaymentRedirectUrlDto, target: string = null): string {
    return this.paymentRedirectUrlPattern
      .replace('{cinemaId}', request.state.cinemaId)
      .replace('{orderId}', request.state.orderId)
      .replace('{target}', target ? encodeURIComponent(target) : this.createTarget(request))
      .replace('{paymentChannel}', request.paymentChannel);
  }

  public createTarget(request: CreatePaymentRedirectUrlDto): string {
    return encodeURIComponent(
      window.location.origin +
        (window.location.origin.endsWith('/') ? '' : '/') +
        this.router
          .createUrlTree(['payment'], {
            queryParams: {
              orderId: request.state.orderId,
              cinemaId: request.state.cinemaId,
            },
          })
          .toString()
    );
  }
}
